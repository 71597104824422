<i18n lang="yaml">
ja:
  head: 気になるトークルームに行ってみよう！
  button-new: トークルームを作ってみんなと話そう
  not-data: 現在トークルームはありません
  more: もっとみる
  login-dialog:
    message: トークルームの作成にはログインが必要です。
    close: 戻る
    confirm: ログイン
en:
  head: Let's go to the talk room you are interested in!
  button-new: Create a talk room and talk to everyone.
  not-data: No talk room
  more: More
  login-dialog:
    message: You must be logged in to create a talk room.
    close: Back
    confirm: Login
</i18n>

<template>
  <div class="ho-top-talkroom-list">
    <div class="head">
      <div class="text">
        <IconFriend class="icon-friend" />{{ i18n.t('head') }}
      </div>
    </div>
    <!-- ▼▼▼ トークルーム ▼▼▼ -->
    <div class="talkroom-list-container">
      <template v-if="talkroomList.length > 0">
        <ul class="talkroom-list">
          <li
            v-for="(talkroom, i) in talkroomList"
            :key="`talkroom-${i}`"
            class="item"
          >
            <HoTopTalkroomCard
              class="card"
              :talkroom="talkroom.channelSession"
              :housing-world="talkroom.housingWorld"
              @click="onShowEntranceDialog(talkroom.channelSession)"
            />
          </li>
          <li class="item-more-button">
            <div class="more-button-container-sp">
              <HaLink class="more-button-sp" to="/talkroom/list">
                <div class="icon-leader">
                  <IconLeader class="icon" />
                </div>
                <p class="text">{{ i18n.t('more') }}</p>
              </HaLink>
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <p class="text">{{ i18n.t('not-data') }}</p>
        <div class="new-button-container">
          <HoMyVketButton class="button" @click="onShowCreateDialog"
            ><IconEdit class="icon-edit" />{{
              i18n.t('button-new')
            }}</HoMyVketButton
          >
        </div>
      </template>
    </div>
    <!-- ▲▲▲ トークルーム ▲▲▲ -->
    <template v-if="talkroomList.length < 0">
      <div class="more-button-container">
        <HaLink class="more-button" to="/talkroom/list">
          <HoMyVketButton class="button" outline>{{
            i18n.t('more')
          }}</HoMyVketButton>
        </HaLink>
      </div>
    </template>
  </div>
  <HoTalkroomCreateDialog
    :is-open="isShowCreateDialog"
    :user-name="userName"
    @close="closeCreateDialog"
    @create-room="onCreateRoom"
  />
  <HoTalkroomEntranceDialog
    :is-open="isShowEntranceDialog"
    :room="targetEntranceRoom"
    :housing-world="targetHousingWorld"
    @close="closeEntranceDialog"
    @enter-housing-world="onEnterHousingWorld"
  />
</template>

<script setup lang="ts">
import { DeepReadonly } from 'vue'
import IconLeader from '@/assets/icons/icon_leader.svg'
import IconFriend from '@/assets/icons/icon_friend.svg'
import { PositionChannel } from '@/models/channelSession'
import { HousingWorld } from '@/models/housingWorld'
import IconEdit from '@/assets/icons/icon_pen_to_square_solid.svg'

import type { EnterHousingWorld } from '@/composables/useInGame'

type Props = {
  channelSessionList: PositionChannel[]
}
const props = defineProps<Props>()

type Emits = {
  (e: 'enterHousingWorld', options: EnterHousingWorld): void
  (e: 'createRoom', roomName: string): void
}

const emit = defineEmits<Emits>()

const i18n = useI18n()

const { state } = inject(housingWorldsInjectionKey)!
const { state: profileState } = inject(myProfileInjectionKey)!

const isShowCreateDialog = ref(false)
const isShowEntranceDialog = ref(false)
const targetEntranceRoom = ref<DeepReadonly<PositionChannel | null>>(null)
const targetHousingWorld = ref<DeepReadonly<HousingWorld> | null>(null)

const housingWorldList = computed(() => state.value.housingWorlds)
const talkroomList = computed(() => {
  return (
    props.channelSessionList
      .map((session) => {
        return {
          channelSession: session,
          housingWorld:
            housingWorldList.value.find(
              (world) => world.worldId === session.worldCode
            ) ?? null,
        }
      })
      // NOTE: housingWorldがない(=ハウジング以外のワールド)は除外
      .filter((talkroom) => talkroom.housingWorld)
  )
})

const userName = computed(() => profileState.value.profile?.name || '')

const onShowEntranceDialog = (room: DeepReadonly<PositionChannel>) => {
  targetEntranceRoom.value = room
  targetHousingWorld.value =
    housingWorldList.value.find((item) => item.worldId === room.worldCode) ||
    null
  isShowEntranceDialog.value = true
}

const closeCreateDialog = () => {
  isShowCreateDialog.value = false
}

const closeEntranceDialog = () => {
  isShowEntranceDialog.value = false
}

const onCreateRoom = (roomName: string) => {
  emit('createRoom', roomName)
}

const onEnterHousingWorld = (options: EnterHousingWorld) => {
  emit('enterHousingWorld', options)
}
const onShowCreateDialog = () => {
  isShowCreateDialog.value = true
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/breakpoints.module.scss' as b;

.ho-top-talkroom-list {
  background: rgba(v.$text-body, 0.5);
  border-radius: 10px;
  padding: v.space(8) 0 v.space(4);
  position: relative;
  width: 424px;

  @media screen and (max-width: b.$pc-content-medium-width) {
    width: 320px;
  }

  @include m.tb {
    background: transparent;
    border-radius: 0;
    padding: 0;
    width: 100%;
  }

  > .head {
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: v.space(-4);
    @include m.tb {
      position: relative;
      top: 0;
      width: 100%;
    }

    > .text {
      align-items: center;
      background: v.$primary-color;
      border-radius: 5px;
      color: v.$white;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      height: 34px;
      justify-content: center;
      line-height: 1;
      min-width: 180px;
      padding: v.space(2);

      @include m.tb {
        border-radius: 0;
        font-size: 10px;
        height: 18px;
        line-height: 14px;
        width: 100%;
      }
    }
  }

  .new-button-container {
    padding-bottom: v.space(2);

    .button {
      align-items: center;
      display: flex;
      font-weight: 700;
      gap: 5px;
      justify-content: center;
      margin: 0 auto;

      @include m.tb {
        font-size: 12px;
        font-weight: 400;
        height: auto;
        margin: 0 auto;
        padding: v.space(1) v.space(3);
        width: auto;
      }
    }
  }

  .more-button-container {
    display: flex;
    justify-content: center;
    margin-top: v.space(2);

    @include m.tb {
      display: none;
    }
  }

  .more-button {
    > .button {
      background: transparent;
      border-color: v.$white;
      color: v.$white;
      font-size: 14px;
      height: 40px;
      width: 150px;

      &:hover,
      &:focus {
        background: rgba(v.$text-body, 0.5);
        border-color: v.$primary-color;
        color: v.$primary-color;
      }
    }
  }

  .talkroom-list-container {
    @extend %scroll-bar;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 v.space(4);

    > .text {
      align-items: center;
      color: v.$white;
      display: flex;
      flex: 1;
      justify-content: center;
      padding: v.space(25) 0;
      @include m.tb {
        font-size: 12px;
        line-height: 16px;
        padding: v.space(2) 0;
      }
    }

    @include m.tb {
      background: rgba(v.$text-body, 0.5);
    }
  }

  .talkroom-list {
    @extend %scroll-bar;
    display: flex;
    flex-direction: column;
    gap: v.space(4);
    max-height: calc(100vh - v.$header-height-pc - v.space(126));
    min-height: v.space(18);
    overflow: auto;
    @include m.tb {
      flex-direction: row;
      gap: 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    > .item {
      display: flex;
      gap: 5px;
      padding: v.space(2);

      @include m.tb {
        padding: v.space(1);
      }

      > .icon {
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        height: 50px;
        width: 50px;

        > .link {
          display: block;
          transition: filter 0.2s;

          &:hover {
            filter: brightness(1.1);
          }
        }
      }

      > .content {
        flex-grow: 1;

        > .date {
          color: v.$white;
          font-size: 12px;
        }
      }

      .text {
        background: v.$text-body;
        border-radius: 5px;
        margin-left: v.space(1);
        margin-top: v.space(1);
        padding: v.space(0.5) v.space(1);
        position: relative;

        &::before {
          background: v.$text-body;
          bottom: 0;
          clip-path: polygon(100% 0, 0 50%, 100% 100%);
          content: '';
          display: block;
          height: 7px;
          left: -4px;
          margin: auto;
          position: absolute;
          top: 0;
          width: 5px;
        }

        > .link {
          @include m.word-ellipsis(1);
          color: v.$white;
          font-size: 14px;

          &:hover {
            color: v.$primary-color;
          }
        }
      }

      .user-name {
        @include m.word-ellipsis(1);
        font-size: 12px;
        margin-top: v.space(1);

        > .link {
          color: v.$white;
          display: block;

          &:hover {
            color: v.$base-link-hover-color;
          }
        }

        .prefix {
          font-size: 10px;
          margin-right: v.space(1);
        }
      }
    }
  }

  .list-container {
    align-items: center;
    background: rgba(v.$text-body, 0.5);
    display: flex;
    height: 72px;
    justify-content: center;

    @include m.tb {
      display: none;
    }
  }

  .icon-list {
    // li要素のz-indexにマイナスをつける影響への対策
    position: relative;
    z-index: 1;

    @include m.tb {
      background-color: v.$primary-color;
    }
  }

  .icon-area {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: v.space(2);
    justify-content: center;
    position: relative;
    z-index: var(--z-number);

    &:not(:last-child) {
      margin-right: v.space(-2);
    }

    .icon {
      border: solid 2px v.$gray;
    }

    .entry {
      display: flex;
      gap: v.space(2);

      > .text {
        color: v.$white;
        font-size: 10px;
      }
    }
  }

  .icon-user {
    fill: v.$white;
    height: 10px;
    width: 10px;
  }

  .more-button-container-sp {
    align-items: center;
    background: rgba(v.$text-body, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 72px;
  }

  .more-button-sp {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: v.space(1);
    justify-content: center;

    .text {
      color: v.$white;
      font-size: 10px;
    }
  }

  .item-more-button {
    display: none;

    @include m.tb {
      display: inline-block;
    }
  }

  .icon-leader {
    align-items: center;
    background: rgba(v.$text-body, 0.5);
    border: 1px solid v.$white;
    border-radius: 25px;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;

    > .icon {
      fill: v.$white;
      height: 15px;
      width: 15px;
    }

    .swiper-slide {
      width: auto;
    }
  }

  .icon-friend {
    display: none;
    fill: v.$white;
    height: 10px;
    width: 10px;

    @include m.tb {
      display: inline-block;
    }
  }

  .icon-edit {
    fill: v.$white;
    height: 20px;
    width: 20px;
  }
}
</style>
