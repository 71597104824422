<!-- 未ログインTOP用ストアカードリスト -->
<template>
  <ul class="ho-top-event-list">
    <li v-for="store in storeList" :key="store.item.id" class="item">
      <HoCloudStoreCard
        :store-asset="{ ...store, profile: null }"
        :is-loading-item-list="isLoadingItemList"
        @click-favorite="clickFavorite"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { DeepReadonly } from 'vue'

// models
import { ItemPurchased } from '@/models/cloudstore'

type Props = {
  storeList: DeepReadonly<ItemPurchased[]>
  isLoadingItemList: boolean
}
const props = defineProps<Props>()
type Emits = {
  (emit: 'clickFavorite'): void
}
const emit = defineEmits<Emits>()

// いいねボタンクリック
const clickFavorite = () => {
  // NOTE: 未ログイン状態で表示されるコンポーネントのため、お気に入りステータスやストアの情報は不要
  emit('clickFavorite')
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$gap-pc: v.space(5);
$gap-sp: 10px;

.ho-top-event-list {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-pc;
  @include m.sp() {
    gap: $gap-sp;
  }

  > .item {
    width: calc((100% - $gap-pc * 3) / 4);

    &:nth-child(n + 5) {
      display: none;
    }
    @include m.sp() {
      width: calc((100% - $gap-sp) / 2);

      &:nth-child(n + 3) {
        width: calc((100% - $gap-sp * 2) / 3);
      }

      &:nth-child(n + 6) {
        display: none;
      }
    }
  }
}
</style>
