<template>
  <ul class="ho-top-world-list">
    <li v-for="world in worldList" :key="world.id" class="item">
      <HoCardThumbnail>
        <template #image>
          <HoCardThumbnailImage
            :src="world.thumbnail?.url"
            no-image="/images/no-image-1024x512.webp"
            hover-able
            :alt="`${world.name}：サムネイル画像`"
            @click="
              onEnterWorldPortal({
                ingameUrl: world.ingameUrl,
              })
            "
          />
        </template>

        <!-- ▼▼▼ 訪問者数 ▼▼▼ -->
        <template v-if="!world.isOfficial" #bottom-left>
          <HmVisitor :count="world.visitCount" :threshold="99999" icon-foot />
        </template>
        <!-- ▲▲▲ 訪問者数 ▲▲▲ -->

        <!-- ▼▼▼ お気に入り数 ▼▼▼ -->
        <template #bottom-right>
          <HoMyVketFavorite
            :count="world.numberOfLikes"
            :is-liked="world.liked"
            @click="clickFavorite"
          />
        </template>
        <!-- ▲▲▲ お気に入り数 ▲▲▲ -->
      </HoCardThumbnail>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { DeepReadonly } from 'vue'

// models
import { WorldPortal } from '@/models/worldPortal'

// composables
import { EnterWorldPortal } from '@/composables/useInGame'

type Props = {
  worldList: DeepReadonly<WorldPortal[]>
}
const props = defineProps<Props>()
type Emits = {
  (emit: 'clickFavorite'): void
  (emit: 'enterWorldPortal', options: EnterWorldPortal): void
}
const emit = defineEmits<Emits>()

// いいねボタンクリック
const clickFavorite = () => {
  // NOTE: 未ログイン状態で表示されるコンポーネントのため、お気に入りステータスやワールドの情報は不要
  emit('clickFavorite')
}

const onEnterWorldPortal = (options: EnterWorldPortal) => {
  emit('enterWorldPortal', options)
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$gap-pc: v.space(5);
$gap-sp: 10px;

.ho-top-world-list {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-pc;
  @include m.sp() {
    gap: $gap-sp;
  }

  > .item {
    width: calc((100% - $gap-pc * 2) / 3);

    &:nth-child(n + 4) {
      width: calc((100% - $gap-pc * 3) / 4);
    }

    &:nth-child(n + 8) {
      display: none;
    }
    @include m.sp() {
      width: 100%;

      &:nth-child(n + 2) {
        width: calc((100% - $gap-sp) / 2);
      }

      &:nth-child(n + 4) {
        display: none;
      }
    }
  }
}
</style>
