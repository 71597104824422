<i18n lang="yaml">
ja:
  title: ストアアイテムが非公開になりました
  description:
    1: お客様がストアに出品している下記のアイテムについて、運営判断により非公開措置を行いました。
    2: アイテムの確認後、申し立てを行う場合は「お問い合わせ」より連絡をお願いします。
  itemName: 'アイテム名： {name}'
  reason: '非公開理由： {reason}'
  contact: お問い合わせ
  mypage: アイテムを確認
en:
  title: Items in your Store have been set to private
  description:
    1: 'The My Vket team has unpublished Items in your Store by the following reasons:'
    2: If you wish to submit an appeal, please contact us through "Contact".
  itemName: 'Name of Item: {name}'
  reason: 'Reason: {reason}'
  contact: Contact
  mypage: View Item List
</i18n>

<template>
  <HaBaseDialog
    v-if="forcedPrivateItems.length && !isClose"
    class="ho-cloud-store-ban-dialog"
    @close="onClose"
  >
    <div class="modal">
      <div class="header">
        {{ i18n.t('title') }}
      </div>
      <div class="body">
        <div class="content">
          <p class="description">
            {{ i18n.t('description.1') }}
          </p>
          <ul class="item-list">
            <li
              v-for="privateItem in forcedPrivateItems"
              :key="privateItem.item.id"
              class="item"
            >
              <p class="item-text">
                <span>{{
                  i18n.t('itemName', { name: privateItem.item.name })
                }}</span>
              </p>
              <p class="item-text">
                <span>{{
                  i18n.t('reason', {
                    reason: formatReasonText(privateItem.reason),
                  })
                }}</span>
              </p>
            </li>
          </ul>
          <p class="description">
            {{ i18n.t('description.2') }}
          </p>
        </div>
        <div class="button-wrapper">
          <HaLink to="/contact">
            <HoMyVketButton class="button">
              {{ i18n.t('contact') }}
            </HoMyVketButton>
          </HaLink>
          <HaLink to="/mypage/assets/avatar">
            <HoMyVketButton class="button">
              {{ i18n.t('mypage') }}
            </HoMyVketButton>
          </HaLink>
        </div>
      </div>
    </div>
  </HaBaseDialog>
</template>

<script setup lang="ts">
const i18n = useI18n()

const { state } = useCloudStore()

const isClose = ref(false)
const forcedPrivateItems = computed(() => state.value.forcedPrivateItems)

const formatReasonText = (reason: string) => {
  return i18n.t(`cloudstore.private.reason-category.${reason}`)
}

const onClose = () => {
  isClose.value = true
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-cloud-store-ban-dialog {
  $modal-content-offset: v.space(5);

  :deep(.dialog-window) {
    border-radius: 5px;
    margin: 0 v.space(4);
    max-width: 640px;
    width: 100%;

    .content {
      padding: 0;
      width: 100%;
    }
  }

  .modal {
    @extend %scroll-bar;
    max-height: calc(100vh - v.$header-height-pc - $modal-content-offset * 2);
    overflow: auto;
    @include m.tb() {
      max-height: calc(
        100vh - v.$header-height-pc - v.$sp-toolbar-height -
          $modal-content-offset * 2
      );
    }
    @include m.sp() {
      max-height: calc(
        100vh - v.$header-height-sp - v.$sp-toolbar-height -
          $modal-content-offset * 2
      );
    }
  }

  .header {
    background: v.$gray;
    border-radius: 5px 0 0;
    display: flex;
    font-weight: 900;
    justify-content: center;
    margin: auto;
    padding: v.space(4);
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: v.space(4);
    max-height: calc(100vh - v.$header-height-pc - $modal-content-offset * 10);

    .content {
      color: v.$text-body;
      padding: v.space(8) v.space(8) v.space(4);
      text-align: center;

      .description {
        text-align: left;
      }

      > .item-list {
        gap: v.space(2);
        margin: v.space(4) 0;
      }

      .item {
        background: v.$gray;
        font-weight: 900;
        margin-top: v.space(2);
        padding: v.space(2);
        text-align: left;

        .item-text {
          display: grid;
          grid-template-columns: auto 1fr;

          @include m.sp() {
            grid-template-columns: 1fr;
            margin: v.space(1) 0;
          }
        }
      }

      .item:nth-child(0) {
        margin-top: 0;
      }
    }

    > .button-wrapper {
      background: v.$gray;
      border-radius: 5px 0 0;
      display: grid;
      font-weight: 900;
      gap: v.space(4);
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      padding: v.space(8) v.space(4);

      @include m.sp() {
        padding: v.space(4);
      }

      .button {
        padding: v.space(2) v.space(5);
        width: 100%;
      }
    }
  }
}
</style>
