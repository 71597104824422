<i18n lang="yaml">
ja:
  title: MyVketで思いっきり“スキ！”を楽しんじゃおう！
  kv_button: 新規登録/ログイン
  points:
    point_1: バーチャル空間もアバターも自分好みに。クリエイティブを楽しもう！
    point_2: イベントを手軽に開催・気軽に参加。自由自在なコミュニケーション。
    point_3: |
      「バーチャルマーケット」へ
      らくらく出展！
  event:
    title: イベント
    lead: バーチャルイベントを楽しもう！
    more: その他のイベントを見てみよう
  room:
    title: ルーム
    lead: 色んなルームに遊びにいってみよう！
    more: その他のルームを見てみよう
  world:
    title: ワールド
    lead: クリエイティブな世界に遊びにいってみよう！
    more: その他のワールドを見てみよう
  store:
    title: ストア
    lead: お気に入りのアイテムを見つけよう！
    more: その他のアイテムを見てみよう
  dialog:
    message: ログインが必要です。
    close: 戻る
    confirm: ログイン
en:
  title: Have fun! in My Vket, to your heart's content!
  kv_button: Sign in
  points:
    point_1: Virtual spaces, avatars… you name it! Enjoy your creativity expanding ever more!
    point_2: Host and join events with your friends! A borderless communication awaits you!
    point_3: |
      Easily exhibit your creation
      in “Virtual Market“!
  event:
    title: Event
    lead: Join and enjoy virtual events!
    more: Check other events
  room:
    title: Room
    lead: Visit rooms created by other users!
    more: Explore other rooms
  world:
    title: World
    lead: Visit worlds full of creativity!
    more: Explore other worlds
  store:
    title: Store
    lead: Discover your favorite items!
    more: Check other items
  dialog:
    message: You must be login
    close: Back
    confirm: login
</i18n>

<template>
  <div class="ho-top-not-login">
    <!-- ▼▼▼ ヒーロー ▼▼▼ -->
    <div class="hero">
      <!-- ▼▼▼ キービジュアル ▼▼▼ -->
      <div class="kv-wrapper">
        <div class="kv">
          <div class="content">
            <h1 class="title">
              <HaBaseImage
                :src="
                  isJa
                    ? '/images/top/no-login/floor_nologin_catch.webp'
                    : '/images/top/no-login/floor_nologin_catch_en.webp'
                "
                class="image"
                :alt="i18n.t('title')"
              />
            </h1>
            <div class="button-container">
              <HaBaseButton class="kv-button" @click="onClickLogin">
                <IconLogoMyvket class="icon" />
                {{ i18n.t('kv_button') }}
              </HaBaseButton>
            </div>
          </div>
          <div class="video-container">
            <HaBaseVideo
              ref="videoRef"
              class="video"
              src="/videos/MYVKET_TOP_PAGE.mp4"
              :height="'100%'"
              :width="'100%'"
              :controls="false"
              play
              autoplay
              playsinline
              poster="/images/top/myvket-video-pc.png"
              muted
              loop
              preload="auto"
            />
          </div>
        </div>
        <div class="bg-decoration">
          <HaBaseImage
            src="/images/top/new/hero_bg_decoration_01.webp"
            class="image"
          />
        </div>
      </div>
      <!-- ▲▲▲ キービジュアル ▲▲▲ -->

      <!-- ▼▼▼ ポイント ▼▼▼ -->
      <div class="points-container">
        <ul class="points">
          <li
            v-for="(point, index) in points"
            :key="index"
            class="point"
            :class="`-type-${point.type}`"
          >
            <span class="icon-container">
              <HaBaseImage
                :src="`/images/top/no-login/${point.icon}`"
                class="icon"
              />
            </span>
            <HaBaseImage
              :src="`/images/top/no-login/${point.thumbnail}`"
              class="thumbnail"
            />
            <span class="text">{{ point.text }}</span>
          </li>
        </ul>
      </div>
      <!-- ▲▲▲ ポイント ▲▲▲ -->

      <div class="bg-decoration">
        <HaBaseImage
          src="/images/top/new/hero_bg_decoration_02.webp"
          class="image"
        />
      </div>

      <div class="decoration">
        <HaBaseImage
          src="/images/top/new/news_decoration_01.webp"
          class="image"
        />
      </div>
    </div>
    <!-- ▲▲▲ ヒーロー ▲▲▲ -->

    <!-- ▼▼▼ ニュース ▼▼▼ -->
    <div class="news-wrapper">
      <div class="news-container">
        <div class="news">
          <!-- ▼▼▼ ニュース一覧 ▼▼▼ -->
          <HoTopNewsList :news-list="newsList" />
          <!-- ▲▲▲ ニュース一覧 ▲▲▲ -->
        </div>
      </div>
    </div>
    <!-- ▲▲▲ ニュース ▲▲▲ -->

    <!-- ▼▼▼ イベント ▼▼▼ -->
    <div class="event-wrapper">
      <div class="event-base">
        <div class="event-container">
          <section class="event">
            <div class="section-head -event">
              <h2 class="heading">
                <span class="icon-container"><IconEvent class="icon" /></span
                >{{ i18n.t('event.title') }}
              </h2>
              <p class="lead">{{ i18n.t('event.lead') }}</p>
              <template v-if="isJa">
                <div class="decoration">
                  <HaBaseImage
                    src="/images/top/new/heading_decoration_event.webp"
                    class="image"
                  />
                </div>
              </template>
            </div>
            <!-- ▼▼▼ イベント一覧 ▼▼▼ -->
            <div class="list-container">
              <HoTopEventList
                :event-list="eventList"
                @click-favorite="isOpenConfirm = true"
              />
            </div>
            <!-- ▲▲▲ イベント一覧 ▲▲▲ -->
            <div class="more-button-container">
              <HaLink :to="LINK.EVENT" class="link">
                <HoMyVketButton light class="button">{{
                  i18n.t('event.more')
                }}</HoMyVketButton>
              </HaLink>
            </div>
          </section>
        </div>
        <div class="decoration">
          <HaBaseImage
            src="/images/top/new/event_decoration_01.webp"
            class="image"
          />
        </div>
      </div>
      <div class="morphing-decoration -event-01">
        <HaBaseImage
          src="/images/top/new/morphing_decoration.svg"
          class="image"
        />
      </div>
      <div class="morphing-decoration -event-02">
        <HaBaseImage
          src="/images/top/new/morphing_decoration.svg"
          class="image"
        />
      </div>
    </div>
    <!-- ▲▲▲ イベント ▲▲▲ -->

    <!-- ▼▼▼ ルーム ▼▼▼ -->
    <div class="room-wrapper">
      <div class="room-wrapper-inner">
        <div class="room-container">
          <section class="room">
            <div class="section-head -room">
              <h2 class="heading">
                <span class="icon-container"><IconRoom class="icon" /></span
                >{{ i18n.t('room.title') }}
              </h2>
              <p class="lead">{{ i18n.t('room.lead') }}</p>
              <template v-if="isJa">
                <div class="decoration">
                  <HaBaseImage
                    src="/images/top/new/heading_decoration_room.webp"
                    class="image"
                  />
                </div>
              </template>
            </div>
            <!-- ▼▼▼ ルーム一覧 ▼▼▼ -->
            <div class="list-container">
              <HoTopRoomList
                :room-list="housingList"
                @enter-housing-world="onEnterInGame"
              />
            </div>
            <!-- ▲▲▲ ルーム一覧 ▲▲▲ -->
            <div class="more-button-container">
              <HaLink :to="LINK.TALKROOM" class="link">
                <HoMyVketButton light class="button -bg-white">{{
                  i18n.t('room.more')
                }}</HoMyVketButton>
              </HaLink>
            </div>
          </section>
          <div class="morphing-decoration -room">
            <HaBaseImage
              src="/images/top/new/morphing_decoration.svg"
              class="image"
            />
          </div>
        </div>
        <div class="bg-decoration">
          <HaBaseImage
            src="/images/top/new/room_bg_decoration_01.webp"
            class="image"
          />
        </div>
      </div>
    </div>
    <!-- ▲▲▲ ルーム ▲▲▲ -->

    <!-- ▼▼▼ ワールド ▼▼▼ -->
    <div class="world-wrapper">
      <div class="world-wrapper-inner">
        <div class="world-container">
          <section class="world">
            <div class="section-head -world">
              <h2 class="heading">
                <span class="icon-container"><IconWorld class="icon" /></span
                >{{ i18n.t('world.title') }}
              </h2>
              <p class="lead">{{ i18n.t('world.lead') }}</p>
              <template v-if="isJa">
                <div class="decoration">
                  <HaBaseImage
                    src="/images/top/new/heading_decoration_world.webp"
                    class="image"
                  />
                </div>
              </template>
            </div>
            <!-- ▼▼▼ ワールド一覧 ▼▼▼ -->
            <div class="list-container">
              <HoTopWorldList
                :world-list="worldList"
                @click-favorite="isOpenConfirm = true"
                @enter-world-portal="onEnterInGame"
              />
            </div>
            <!-- ▲▲▲ ワールド一覧 ▲▲▲ -->
            <div class="more-button-container">
              <HaLink :to="LINK.WORLD" class="link">
                <HoMyVketButton light class="button">{{
                  i18n.t('world.more')
                }}</HoMyVketButton>
              </HaLink>
            </div>
          </section>
          <div class="morphing-decoration -world">
            <HaBaseImage
              src="/images/top/new/morphing_decoration.svg"
              class="image"
            />
          </div>
        </div>
        <div class="bg-decoration">
          <HaBaseImage
            src="/images/top/new/world_bg_decoration_01.webp"
            class="image"
          />
        </div>
      </div>
      <div class="decoration">
        <HaBaseImage
          src="/images/top/new/world_decoration_01.webp"
          class="image"
        />
      </div>
    </div>
    <!-- ▲▲▲ ワールド ▲▲▲ -->

    <!-- ▼▼▼ ストア ▼▼▼ -->
    <div class="store-wrapper">
      <div class="store-wrapper-inner">
        <div class="store-container">
          <section class="store">
            <div class="section-head -store">
              <h2 class="heading">
                <span class="icon-container"><IconShop class="icon" /></span
                >{{ i18n.t('store.title') }}
              </h2>
              <p class="lead">{{ i18n.t('store.lead') }}</p>
              <template v-if="isJa">
                <div class="decoration">
                  <HaBaseImage
                    src="/images/top/new/heading_decoration_store.webp"
                    class="image"
                  />
                </div>
              </template>
            </div>
            <!-- ▼▼▼ ストア一覧 ▼▼▼ -->
            <div class="list-container">
              <!--
                TODO: イベント・ワールド・ルームのカードのスケルトンスクリーン対応後、
                このコンポーネント全体でスケルトンスクリーン対応を行う。
                その際、以下の isLoadingItemList を適切に設定する。
              -->
              <HoTopStoreList
                :store-list="storeList"
                :is-loading-item-list="false"
                @click-favorite="isOpenConfirm = true"
              />
            </div>
            <!-- ▲▲▲ ストア一覧 ▲▲▲ -->
            <div class="more-button-container">
              <HaLink :to="LINK.CLOUDSTORE" class="link">
                <HoMyVketButton light class="button -bg-white">{{
                  i18n.t('store.more')
                }}</HoMyVketButton>
              </HaLink>
            </div>
          </section>
        </div>
        <div class="bg-decoration">
          <HaBaseImage
            src="/images/top/new/store_bg_decoration_01.webp"
            class="image"
          />
        </div>
        <div class="morphing-decoration -store">
          <HaBaseImage
            src="/images/top/new/morphing_decoration.svg"
            class="image"
          />
        </div>
      </div>
      <div class="decoration">
        <HaBaseImage
          src="/images/top/new/store_decoration_01.webp"
          class="image"
        />
      </div>
    </div>
    <!-- ▲▲▲ ストア ▲▲▲ -->

    <!-- ▼▼▼ 未ログイン時、ログインを促すダイアログ ▼▼▼ -->
    <ClientOnly>
      <template v-if="!isGottenMe">
        <HoConfirmDialog
          class="confirm-dialog"
          :is-open="isOpenConfirm"
          :message="i18n.t('dialog.message')"
          :close-message="i18n.t('dialog.close')"
          :confirm-message="i18n.t('dialog.confirm')"
          @confirm="onClickLogin"
          @cancel="closeDialog"
          @close="closeDialog"
        />
      </template>
    </ClientOnly>
    <!-- ▲▲▲ 未ログイン時、ログインを促すダイアログ ▲▲▲ -->
  </div>
</template>

<script lang="ts" setup>
// assets
import IconLogoMyvket from '@/assets/icons/icon_logo_myvket.svg'
import IconEvent from '@/assets/icons/icon_event.svg'
import IconRoom from '@/assets/icons/icon_room.svg'
import IconWorld from '@/assets/icons/icon_world.svg'
import IconShop from '@/assets/icons/icon_shop.svg'
import { EnterInGameOption } from '@/composables/useInGame'

type Emits = {
  (emit: 'enterInGame', option: EnterInGameOption): void
}
const emit = defineEmits<Emits>()

const i18n = useI18n()
const toast = useToast()

const { addToast } = toast
const isJa = computed(() => i18n.locale.value === 'ja')
const { login } = useAuth()

const { isGottenMe } = inject(authInjectionKey)!

// ニュース
const { state: newsState } = inject(newsComposablesInjectionKey)!
const newsList = computed(() => newsState.value.newsList)

// イベント
const { state: eventState } = inject(myVketEventComposableInjectionKey)!
const eventList = computed(() => eventState.value.myVketEventList)

// ルーム
const { state: housingState } = inject(housingWorldsInjectionKey)!
const housingList = computed(() => housingState.value.housingWorlds)

// ワールド
const { state: worldState } = inject(worldPortalComposablesInjectionKey)!
const worldList = computed(() => worldState.value.worldPortalList)

// ストア
const { state: storeState } = inject(cloudStoreInjectionKey)!
const storeList = computed(() => storeState.value.assetList)

// ポイント
const points = computed(() => [
  {
    type: '01',
    icon: `floor_nologin_point_parts01${isJa.value ? '' : '_en'}.webp`,
    text: i18n.t('points.point_1'),
    thumbnail: 'floor_nologin_point_image01.webp?20240605',
  },
  {
    type: '02',
    icon: `floor_nologin_point_parts02${isJa.value ? '' : '_en'}.webp`,
    text: i18n.t('points.point_2'),
    thumbnail: 'floor_nologin_point_image02.webp?20240605',
  },
  {
    type: '03',
    icon: `floor_nologin_point_parts03${isJa.value ? '' : '_en'}.webp`,
    text: i18n.t('points.point_3'),
    thumbnail: 'floor_nologin_point_image03.webp?20240605',
  },
])

const isOpenConfirm = ref(false)

//  ダイアログを閉じる
const closeDialog = () => {
  isOpenConfirm.value = false
}

// インゲームに遷移
const onEnterInGame = (options: EnterInGameOption) => {
  emit('enterInGame', options)
}

// ログインして現在のページに戻る
const onClickLogin = async () => {
  const result = await login()
  if (result) {
    addToast(i18n.t(result), 'error')
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/breakpoints.module.scss' as b;

// 変数
$bg-primary: #ffe8c3;
$bg-primary-light: #fff4e3;
$points-inset-pc: 60px;
$points-inset-sp: 80px;

.ho-top-not-login {
  background: $bg-primary-light;
  overflow: hidden;

  > .hero {
    padding-bottom: v.space(16);
    position: relative;
    z-index: 1;
    @include m.sp() {
      padding-bottom: v.space(4);
    }

    .kv-wrapper {
      position: relative;

      > .kv {
        align-items: center;
        background: v.$gray;
        border-bottom-left-radius: 120px;
        border-bottom-right-radius: 120px;
        box-shadow: 0 0 64px 0 rgba(v.$primary-color, 0.75);
        display: flex;
        height: 600px;
        justify-content: center;
        overflow: hidden;
        padding-bottom: $points-inset-pc;
        position: relative;
        @include m.sp() {
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 30px;
          height: 240px;
          padding-bottom: $points-inset-sp;
        }

        > .content {
          max-width: 574px;
          position: relative;
          z-index: 1;
          @include m.sp() {
            max-width: 280px;
          }
        }

        .title {
          width: 100%;
        }

        .button-container {
          margin: v.space(8) auto 0;
          width: 100%;
          @include m.sp() {
            margin-top: v.space(4);
            width: 228px;
          }
        }
      }

      .video-container {
        inset: 0;
        position: absolute;

        > .video {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .kv-button {
        align-items: center;
        background: linear-gradient(
          #ffa011 58.85%,
          v.$primary-button-default-color
        );
        border: 2px solid v.$white;
        border-radius: 9999px;
        box-shadow: 0 0 16px 0 rgba(v.$primary-button-default-color, 0.75);
        color: v.$white;
        display: flex;
        fill: v.$white;
        font-size: 20px;
        gap: v.space(2);
        height: 64px;
        justify-content: center;
        transition: all 0.2s;
        width: 100%;
        @include m.sp() {
          font-size: 16px;
          gap: v.space(1);
          height: 44px;
        }

        &:hover {
          background: linear-gradient(#ffbe18, v.$primary-hover-color);
        }

        > .icon {
          width: 24px;
          @include m.sp() {
            width: 20px;
          }
        }
      }

      > .bg-decoration {
        display: block;
        height: 399px;
        position: absolute;
        right: 0;
        top: calc(100% - 160px);
        width: 230px;
        z-index: -1;
        @include m.sp() {
          display: none;
        }

        > .image {
          height: 100%;
          object-fit: contain;
          object-position: right top;
          width: 100%;
        }
      }
    }

    > .points-container {
      margin: -$points-inset-pc auto 0;
      max-width: b.$pc-content-medium-width + v.space(8);
      padding: 0 v.space(4);
      position: relative;
      z-index: 2;
      @include m.sp() {
        margin-top: -$points-inset-sp;
      }

      > .points {
        align-items: center;
        display: flex;
        gap: 50px;
        justify-content: center;
        @media screen and (max-width: b.$pc-content-min-width) {
          display: grid;
          gap: v.space(4) v.space(3);
          grid-template-areas: 'type-1 type-2' 'type-3 type-3';
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .point {
      background: v.$white;
      border: 3px solid;
      border-radius: 0 20px;
      padding: v.space(2) v.space(2) v.space(4);
      position: relative;

      > .icon-container {
        display: block;
        height: 60px;
        left: -20px;
        position: absolute;
        top: -20px;
        width: 220px;

        > .icon {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      > .thumbnail {
        width: 100%;
      }

      > .text {
        align-items: center;
        border-radius: 9999px;
        bottom: -(v.space(4));
        color: v.$white;
        display: flex;
        font-weight: bold;
        height: 90px;
        justify-content: center;
        left: v.space(5);
        padding: 0 v.space(4);
        position: absolute;
        right: v.space(5);
        text-align: center;
        white-space: pre-wrap;
      }
      // タイプ01
      &.-type-01 {
        border-color: v.$talkroom-color;
        grid-area: type-1;

        > .text {
          background: v.$talkroom-color;
        }
      }
      // タイプ02
      &.-type-02 {
        border-color: v.$play-color;
        grid-area: type-2;

        > .text {
          background: v.$play-color;
        }
      }
      // タイプ03
      &.-type-03 {
        border-color: v.$primary-color;
        grid-area: type-3;

        > .text {
          background: v.$primary-color;
        }
      }
      @media screen and (max-width: b.$pc-content-min-width) {
        border-radius: 0 10px;
        height: 100%;
        padding: v.space(2);

        > .icon-container {
          height: auto;
          left: v.space(-2.5);
          min-width: 120px;
          top: v.space(-3.5);
          width: 20vw;
        }

        > .thumbnail {
          flex-shrink: 0;
        }

        > .text {
          display: block;
          flex-grow: 1;
          font-size: 12px;
          height: auto;
          inset: auto;
          margin-top: v.space(2);
          padding: 0;
          position: relative;
          text-align: left;
          width: auto;
        }

        &.-type-01,
        &.-type-02,
        &.-type-03 {
          display: flex;
          flex-direction: column;

          > .text {
            background: none;
            padding: none;
          }
        }

        &.-type-01 {
          > .text {
            color: v.$talkroom-color;
          }
        }

        &.-type-02 {
          > .text {
            color: v.$play-color;
          }
        }

        &.-type-03 {
          align-items: center;
          flex-direction: row;
          gap: v.space(2);

          > .thumbnail {
            // NOTE: SP時の上段の画像と幅を合わせるための計算式
            width: calc((100% - (3px * 2 + v.space(2) * 2 + v.space(3))) / 2);
          }

          > .text {
            color: v.$primary-color;
            flex-grow: 1;
            margin-top: 0;
          }
        }
      }
    }

    > .bg-decoration {
      bottom: 0;
      height: 209px;
      left: 0;
      position: absolute;
      width: 222px;
      z-index: -1;
      @include m.sp() {
        display: none;
      }

      > .image {
        height: 100%;
        object-fit: contain;
        object-position: left bottom;
        width: 100%;
      }
    }

    > .decoration {
      bottom: -50px;
      height: 146px;
      left: 0;
      position: absolute;
      width: 93px;
      z-index: 1;
      @include m.sp() {
        display: none;
      }

      > .image {
        height: 100%;
        object-fit: contain;
        object-position: left center;
        width: 100%;
      }
    }
  }

  > .news-wrapper {
    background: $bg-primary;
    padding-top: v.space(8);
    position: relative;
    @include m.sp() {
      padding-top: v.space(4);
    }

    .news-container {
      margin: 0 auto;
      max-width: v.$pc-content-body-width + v.space(8);
      padding: 0 v.space(4);
      position: relative;
      z-index: 2;
    }
  }

  > .event-wrapper {
    padding-top: v.space(16);
    position: relative;
    @include m.sp() {
      padding-top: v.space(8);
    }

    &::before {
      background: $bg-primary;
      content: '';
      display: block;
      height: 300px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    > .event-base {
      background: v.$white;
      border-radius: v.space(16);
      box-shadow: 0 0 32px 0 rgba(v.$primary-color, 0.25);
      margin: 0 auto;
      max-width: 1700px;
      padding: v.space(16) 0;
      position: relative;
      z-index: 1;
      @include m.sp() {
        border-radius: v.space(8);
        padding: v.space(8) 0;
      }

      > .event-container {
        margin: 0 auto;
        max-width: v.$pc-content-body-width + v.space(8);
        padding: 0 v.space(4);
        position: relative;
        z-index: 1;
      }

      > .decoration {
        bottom: -20px;
        height: 146px;
        position: absolute;
        right: -60px;
        width: 158px;
        @include m.sp() {
          display: none;
        }

        > .image {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }

  > .room-wrapper {
    background: v.$white;
    margin-top: 100px;
    @include m.sp() {
      margin-top: v.space(12);
    }

    > .room-wrapper-inner {
      background: $bg-primary-light;
      border-bottom-right-radius: v.space(24);
      overflow: hidden;
      padding-bottom: v.space(16);
      position: relative;
      @include m.sp() {
        border-bottom-right-radius: 30px;
        padding-bottom: v.space(8);
      }

      > .room-container {
        margin: 0 auto;
        max-width: v.$pc-content-body-width + v.space(8);
        padding: 0 v.space(4);
        position: relative;
        z-index: 1;

        > .room {
          position: relative;
          z-index: 1;
        }
      }

      > .bg-decoration {
        bottom: 0;
        height: 236px;
        position: absolute;
        right: 0;
        width: 261px;
        @include m.sp() {
          display: none;
        }

        > .image {
          height: 100%;
          object-fit: contain;
          object-position: right bottom;
          width: 100%;
        }
      }
    }
  }

  > .world-wrapper {
    background: $bg-primary-light;
    position: relative;

    > .world-wrapper-inner {
      background: v.$white;
      border-bottom-left-radius: v.space(24);
      border-top-left-radius: v.space(24);
      overflow: hidden;
      padding: v.space(16) 0;
      position: relative;
      @include m.sp() {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        padding: v.space(8) 0;
      }

      > .world-container {
        margin: 0 auto;
        max-width: v.$pc-content-body-width + v.space(8);
        padding: 0 v.space(4);
        position: relative;
        z-index: 1;

        > .world {
          position: relative;
          z-index: 1;
        }
      }

      > .bg-decoration {
        bottom: 0;
        height: 236px;
        left: 0;
        position: absolute;
        width: 349px;
        @include m.sp() {
          display: none;
        }

        > .image {
          height: 100%;
          object-fit: contain;
          object-position: left bottom;
          width: 100%;
        }
      }
    }

    > .decoration {
      height: 97px;
      left: 40px;
      position: absolute;
      top: -60px;
      width: 93px;
      z-index: 1;
      @include m.sp() {
        display: none;
      }

      > .image {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  .store-wrapper {
    background: v.$white;
    position: relative;

    > .store-wrapper-inner {
      background: $bg-primary-light;
      border-top-right-radius: v.space(24);
      overflow: hidden;
      padding: v.space(16) 0 v.space(50);
      position: relative;
      @include m.sp() {
        border-top-right-radius: 30px;
        padding: v.space(8) 0 v.space(68);
      }

      > .store-container {
        margin: 0 auto;
        max-width: v.$pc-content-body-width + v.space(8);
        padding: 0 v.space(4);
        position: relative;
        z-index: 1;
      }

      > .bg-decoration {
        bottom: 0;
        height: 236px;
        position: absolute;
        right: 0;
        width: 261px;
        @include m.sp() {
          display: none;
        }

        > .image {
          height: 100%;
          object-fit: contain;
          object-position: right bottom;
          width: 100%;
        }
      }
    }

    > .decoration {
      height: 118px;
      position: absolute;
      right: 24px;
      top: -60px;
      width: 128px;
      @include m.sp() {
        display: none;
      }

      > .image {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  .section-head {
    align-items: center;
    display: flex;
    gap: v.space(4);
    min-height: 64px;
    position: relative;
    @include m.sp() {
      flex-direction: column;
      gap: v.space(2);
      min-height: auto;
    }

    > .heading {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      font-size: 32px;
      font-weight: bold;
      gap: v.space(1);

      > .icon-container {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        height: 36px;
        justify-content: center;
        width: 36px;

        > .icon {
          fill: v.$white;
          height: 20px;
          width: 20px;
        }
      }
      @include m.sp() {
        border-radius: 5px;
        font-size: 16px;
        justify-content: center;
        line-height: 1;
        padding: v.space(1.5);
        width: 100%;

        > .icon-container {
          border-radius: 0;
          height: auto;
          width: auto;
        }
      }
    }

    > .lead {
      color: v.$gray-5;
      font-size: 20px;
      font-weight: bold;
      @include m.sp() {
        font-size: 14px;
        text-align: center;
      }
    }

    > .decoration {
      bottom: 0;
      height: 46px;
      position: absolute;
      right: 0;
      top: 0;
      @include m.sp() {
        display: none;
      }

      > .image {
        height: 100%;
      }
    }

    &.-event,
    &.-world {
      > .heading {
        color: v.$play-color;

        > .icon-container {
          background: v.$play-color;
        }
        @include m.sp() {
          background: v.$play-color;
          color: v.$white;
        }
      }
    }

    &.-room {
      > .heading {
        color: v.$talkroom-color;

        > .icon-container {
          background: v.$talkroom-color;
        }
        @include m.sp() {
          background: v.$talkroom-color;
          color: v.$white;
        }
      }
    }

    &.-store {
      > .heading {
        color: v.$store-color;

        > .icon-container {
          background: v.$store-color;
        }
        @include m.sp() {
          background: v.$store-color;
          color: v.$white;
        }
      }
    }
  }

  .list-container {
    @include m.sp() {
      margin-top: v.space(4);
    }
  }

  .more-button-container {
    margin: v.space(6) auto 0;
    max-width: 480px;

    > .link {
      display: block;
      width: 100%;

      > .button {
        font-weight: bold;
        width: 100%;

        &.-bg-white {
          background: v.$white;
        }
      }
    }

    @include m.sp() {
      margin-top: v.space(4);
      max-width: 260px;

      > .link > .button {
        font-size: 14px;
      }
    }
  }

  .morphing-decoration {
    opacity: 0.5;
    position: absolute;

    > .image {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    &.-event-01 {
      height: 238px;
      left: -100px;
      top: -20px;
      transform: rotate(28deg);
      width: 265px;
      @include m.sp() {
        height: 232px;
        left: -80px;
        top: -20px;
        transform: rotate(0);
        width: 240px;
      }
    }

    &.-event-02 {
      bottom: -120px;
      height: 389px;
      right: -120px;
      transform: rotate(28deg);
      width: 433px;
      @include m.sp() {
        bottom: -60px;
        height: 220px;
        right: -110px;
        width: 244px;
      }
    }

    &.-room {
      bottom: -60px;
      height: 370px;
      left: -160px;
      transform: rotate(28deg);
      width: 411px;
      @include m.sp() {
        bottom: -20px;
        height: 174px;
        left: -100px;
        transform: rotate(0);
        width: 180px;
      }
    }

    &.-world {
      bottom: -60px;
      height: 241px;
      right: -100px;
      transform: rotate(150deg);
      width: 268px;
      @include m.sp() {
        bottom: -20px;
        height: 116px;
        right: -40px;
        transform: rotate(0);
        width: 120px;
      }
    }

    &.-store {
      bottom: -200px;
      height: 452px;
      left: -160px;
      transform: rotate(28deg);
      width: 504px;
      @include m.sp() {
        bottom: -360px;
        height: 678px;
        left: 50%;
        transform: translateX(-50%);
        width: 700px;
      }
    }
  }
}
</style>
