<i18n lang="yaml">
ja:
  button: ブースメイカーへ移動
en:
  button: Go to Booth Maker
</i18n>

<template>
  <template v-if="isOpenNotificationDialog">
    <HaBaseDialog
      class="ho-top-notification-dialog"
      @close="closeNotificationDialog"
    >
      <HaImage
        class="notice-image"
        src="/images/top/vket_booth_maker_thumbnail.png"
        @click="goToBoothMakerNotification()"
      />
      <div class="buttons-container">
        <HoMyVketButton @click="goToBoothMakerNotification">
          {{ i18n.t('button') }}
        </HoMyVketButton>
      </div>
    </HaBaseDialog>
  </template>
</template>

<script lang="ts" setup>
const i18n = useI18n()

type Emits = {
  (v: 'confirm'): void
}
const emit = defineEmits<Emits>()
const { isGottenMe } = inject(authInjectionKey)!

/** お知らせバージョン */
const notificationVersion = 1
/* 掲載終了時間 */
const notificationEndDate = vketBoothMarcheEndDateTime

const isOpenNotificationDialog = ref(false)
const closeNotificationDialog = () => {
  isOpenNotificationDialog.value = false
  // NOTE: お知らせを更新した場合はnotificationVersionを更新する
  setSingleCookieValue('isShownNoticeVersion', `v${notificationVersion}`)
}
const goToBoothMakerNotification = () => {
  emit('confirm')
  closeNotificationDialog()
}

onMounted(() => {
  if (isAfterTargetDate(notificationEndDate)) return

  // ログインしていない場合はお知らせダイアログを表示しない
  if (isGottenMe.value) {
    // Cookieから最後に表示したお知らせバージョンを取得
    const isShowNotificationVersion = getSingleCookieValue(
      'isShownNoticeVersion'
    )
    const versionNumber =
      typeof isShowNotificationVersion === 'string'
        ? parseInt(isShowNotificationVersion.replace('v', ''))
        : 0
    // NOTE: お知らせを更新した場合はnotificationVersionを更新する
    if (!versionNumber || versionNumber < notificationVersion) {
      isOpenNotificationDialog.value = true
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-top-notification-dialog {
  :deep(> .dialog-window) {
    width: 50vw;
    @include m.sp {
      padding: v.space(2);
      width: 90vw;
    }

    > .content {
      padding: v.space(2);
    }
  }

  .notice-image {
    cursor: pointer;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: v.space(1);
    position: static;
  }
}
</style>
