<i18n lang="yaml">
ja:
  text:
    title: ようこそMy Vketへ！
    description: |-
      My Vketを楽しむために、あなたの分身（アバター）と自分のお部屋（ルーム）を作りましょう。
      気に入ったアイテムを選ぶだけだからとってもカンタン！さっそく始めてみましょう！
    notice: ※Vket Accountを持っていない方は新規登録が必要です。
  button: 次へ
en:
  text:
    title: Welcome to My Vket!
    description: Create your digital self, Avatar, and your Room to enjoy My Vket. Just choose what you like and you'll be ready to go!
    notice: \* A Vket Account is required. Please sign up if you haven't.
  button: Next
</i18n>
<template>
  <HaBaseDialog
    ref="el"
    class="ho-tutorial-start-modal"
    :class="{ '-landscape': isLandscape }"
    @close="close"
  >
    <div class="inner">
      <div class="body-container">
        <div class="title">
          {{ i18n.t('text.title') }}
        </div>
        <div class="thumbnail">
          <img
            src="@/assets/images/tutorial/tutorial_popup_img.webp"
            class="image"
          />
        </div>
        <p class="description">
          {{ i18n.t('text.description') }}
        </p>
      </div>
      <div class="button-container">
        <HoMyVketButton class="button" @click="startTutorial">
          <span>{{ i18n.t('button') }}</span>
          <IconArrowNext class="arrow -next" />
        </HoMyVketButton>
      </div>
      <div class="footer-container">
        {{ i18n.t('text.notice') }}
      </div>
    </div>
  </HaBaseDialog>
</template>

<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'
import IconArrowNext from '@/assets/icons/icon-arrow-next.svg'

const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')

type Emits = {
  (emit: 'close'): void
  (emit: 'startTutorial'): void
}

const emit = defineEmits<Emits>()

const el = ref(null)
const { height } = useElementSize(el)

// SP横画面判定
const isLandscape = computed(() => height.value < 500)

const close = () => {
  emit('close')
}

const startTutorial = () => {
  emit('startTutorial')
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$modal-content-offset: v.space(5);

.ho-tutorial-start-modal {
  padding: (v.$header-height-pc + $modal-content-offset) $modal-content-offset
    $modal-content-offset;
  @include m.sp() {
    padding-top: v.$header-height-sp + $modal-content-offset;
  }

  // 詳細度を上げるためにclass属性を指定
  &:deep(> .dialog-window[class]) {
    background-color: v.$white;
    border-radius: 10px;
    max-height: 100%;

    > .content {
      @include m.sp() {
        padding: v.space(4);
      }
    }
  }

  .inner {
    color: v.$white;
    width: 100%;
  }

  &.-landscape {
    padding: 0;

    &:deep(> .dialog-window[class]) {
      > .content {
        height: calc(
          100lvh - (v.$header-height-sp + v.$sp-toolbar-height + 80px)
        );
        overflow-y: auto;
      }
    }
  }
}

.body-container {
  max-height: 350px;
  max-width: 350px;

  @include m.sp() {
    max-height: max-content;
  }

  > .title {
    color: v.$text-body;
    font-weight: 900;
    margin-bottom: v.space(4);
    padding: v.space(1);
    text-align: center;
  }

  > .thumbnail {
    margin-bottom: v.space(4);

    @include m.sp() {
      margin-bottom: v.space(2);
    }
  }

  > .description {
    color: v.$text-body;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: v.space(4);
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.button-container {
  align-items: center;
  display: flex;
  height: 52px;
  justify-content: center;
  width: 100%;

  > .button {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: 36px;
    justify-content: center;
    padding: v.space(1);
    position: relative;
    width: 100%;

    @include m.sp() {
      font-size: 14px;
    }
  }

  > .button > .arrow {
    &.-next {
      height: 12px;
      margin-left: auto;
      position: absolute;
      right: v.space(1);

      :deep(path) {
        fill: v.$white;
      }
    }
  }
}

.footer-container {
  color: v.$text-body;
  font-size: 14px;
  line-height: 16px;

  @include m.sp() {
    font-size: 12px;
  }
}
</style>
