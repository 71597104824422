<template>
  <ul class="ho-top-room-list">
    <li v-for="room in roomList" :key="room.id" class="item">
      <div class="card">
        <!-- ▼▼▼ サムネイル ▼▼▼ -->
        <HoCardThumbnail>
          <template #image>
            <div @click="onEnterHousingWorld({ worldId: room.worldId || '' })">
              <HoCardThumbnailImage
                :src="room.thumbnail?.url"
                no-image="/images/no-image-1024x512.webp"
                hover-able
              />
            </div>
          </template>
          <template #bottom-left>
            <HmVisitor
              class="visitor"
              :count="room.numberOfVisits"
              :threshold="99999"
              icon-foot
            />
          </template>
        </HoCardThumbnail>
        <!-- ▲▲▲ サムネイル ▲▲▲ -->

        <!-- ▼▼▼ ユーザー ▼▼▼ -->
        <template v-if="room.user">
          <div class="user">
            <HaLink :to="`/profile/${room.user.vketId}`" class="icon" redirect>
              <HmUserIcon
                :src="room.user.icon.url || ''"
                no-image="/images/no-image-512x512.webp"
                class="user-icon"
              />
            </HaLink>

            <HaLink
              :to="`/profile/${room.user.vketId}`"
              class="name"
              redirect
              >{{ room.user.name }}</HaLink
            >
          </div>
        </template>
        <!-- ▲▲▲ ユーザー ▲▲▲ -->
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { DeepReadonly } from 'vue'

// models
import { HousingWorld } from '@/models/housingWorld'

// composable
import type { EnterHousingWorld } from '@/composables/useInGame'

type Props = {
  roomList: DeepReadonly<HousingWorld[]>
}
const props = defineProps<Props>()
type Emits = {
  (emit: 'enterHousingWorld', options: EnterHousingWorld): void
}
const emit = defineEmits<Emits>()

const onEnterHousingWorld = (options: EnterHousingWorld) => {
  emit('enterHousingWorld', options)
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$gap-pc: v.space(5);
$gap-sp: 10px;

.ho-top-room-list {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-pc;
  @include m.sp() {
    gap: $gap-sp;
  }

  > .item {
    width: calc((100% - $gap-pc * 2) / 3);

    &:nth-child(n + 7) {
      display: none;
    }
    @include m.sp() {
      width: 100%;

      &:nth-child(n + 2) {
        width: calc((100% - $gap-sp) / 2);
      }

      &:nth-child(n + 4) {
        display: none;
      }
    }
  }

  .card {
    background: v.$white;
    border-radius: 10px;

    > .user {
      align-items: center;
      display: flex;
      padding: v.space(2) v.space(2) v.space(3);
      @include m.sp {
        padding: 5px;
      }

      > .icon {
        display: block;
        flex-shrink: 0;
        height: 20px;
        margin-right: v.space(1);
        width: 20px;
        @include m.sp {
          height: 16px;
          width: 16px;
        }
      }

      > .name {
        @include m.word-ellipsis(1);
        flex-grow: 1;
        font-size: 14px;
        @include m.sp {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
