<i18n lang="yaml">
ja:
  head: 参加予定イベント
  head-sp: 参加イベント
  more: もっとみる
  not-event: 参加予定のイベントはありません
  button: イベントTOP
en:
  head: Events you plan to attend
  head-sp: Events
  more: More
  not-event: No events
  button: Events top
</i18n>

<template>
  <div class="ho-top-event-box-participate">
    <div class="head">
      <div class="text">{{ isTb ? i18n.t('head-sp') : i18n.t('head') }}</div>
    </div>
    <div class="content">
      <template v-if="event">
        <div class="item">
          <HaLink :to="`/play/event/${event.id}`" class="link">
            <HaBaseImage
              :src="event?.thumbnail"
              no-image="/images/no-image-1024x512.webp"
              class="image"
              :alt="`サムネイル画像：${event.name}`"
            />
          </HaLink>
        </div>
        <p class="name">{{ event.name }}</p>
        <div class="more-button-container">
          <HaLink
            class="more-button"
            to="/play/event/list?isParticipating=true&sort=newer"
          >
            <HoMyVketButton class="button" outline>{{
              i18n.t('more')
            }}</HoMyVketButton>
          </HaLink>
        </div>
      </template>
      <template v-else>
        <p class="text">{{ i18n.t('not-event') }}</p>
        <div class="more-button-container">
          <HaLink class="more-button" to="/play/event">
            <HoMyVketButton class="button" outline>{{
              i18n.t('button')
            }}</HoMyVketButton>
          </HaLink>
        </div></template
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
// イベント
const { state: eventState } = inject(myVketEventComposableInjectionKey)!
const event = computed(() => eventState.value.myVketEventList[0])

const i18n = useI18n()
const isTb = breakpoints.smaller('pc')
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/breakpoints.module.scss' as b;

.ho-top-event-box-participate {
  background: rgba(v.$text-body, 0.5);
  border-radius: 10px;
  height: 291px;
  padding: v.space(8) 0 v.space(4);
  position: relative;
  width: 342px;

  @media screen and (max-width: b.$pc-content-medium-width) {
    width: 320px;
  }
  @include m.tb {
    border-radius: 2px;
    height: 120px;
    padding: v.space(4) 0 v.space(2);
    width: 98px;
  }

  > .head {
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: v.space(-4);

    @include m.tb {
      top: v.space(-2);
    }

    > .text {
      align-items: center;
      background: v.$primary-color;
      border-radius: 5px;
      color: v.$white;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      height: 34px;
      justify-content: center;
      line-height: 1;
      min-width: 180px;
      padding: v.space(2);

      @include m.tb {
        border-radius: 2px;
        font-size: 10px;
        height: 18px;
        line-height: 14px;
        min-width: 67px;
        padding: v.space(1);
      }
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 100%);
    height: 100%;
    padding: 0 v.space(4);
    @include m.tb {
      overflow: visible;
      padding: 0 v.space(1);
    }

    &.-has-participate {
      height: 400px;
      @include m.tb {
        height: auto;
      }
    }

    .participate {
      @include m.tb {
        display: none;
      }
    }

    .item {
      border: 2px solid v.$white;
      border-radius: 5px;
      overflow: hidden;
      @include m.tb {
        border-radius: 0;
      }

      > .link {
        display: block;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(1.1);
        }

        > .image {
          aspect-ratio: 16 / 9;
        }
      }
    }

    .name {
      @include m.word-ellipsis(1);
      color: v.$white;
      font-size: 14px;

      @include m.tb {
        font-size: 10px;
        line-height: 14px;
      }
    }

    > .text {
      align-items: center;
      color: v.$white;
      display: flex;
      flex: 1;
      justify-content: center;

      @include m.tb {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  .more-button-container {
    display: flex;
    justify-content: center;
    margin-top: v.space(2);
  }

  .more-button {
    > .button {
      background: transparent;
      border-color: v.$white;
      color: v.$white;
      font-size: 14px;
      height: 40px;
      width: 150px;

      @include m.tb {
        align-items: center;
        display: flex;
        font-size: 10px;
        height: 20px;
        justify-content: center;
        white-space: pre;
        width: 82px;
      }

      &:hover,
      &:focus {
        background: rgba(v.$text-body, 0.5);
        border-color: v.$primary-color;
        color: v.$primary-color;
      }
    }
  }
}
</style>
