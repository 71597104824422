<template>
  <ul class="ho-top-event-list">
    <li v-for="event in eventList" :key="event.id" class="item">
      <HoPlayEventCard :event="event" @click-favorite="clickFavorite" />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { DeepReadonly } from 'vue'

// models
import { MyVketEventList } from '@/models/myVketEvent'

type Props = {
  eventList: DeepReadonly<MyVketEventList>
}
const props = defineProps<Props>()
type Emits = {
  (emit: 'clickFavorite'): void
}
const emit = defineEmits<Emits>()

// いいねボタンクリック
const clickFavorite = () => {
  // NOTE: 未ログイン状態で表示されるコンポーネントのため、お気に入りステータスやイベントの情報は不要
  emit('clickFavorite')
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$gap-pc: v.space(5);
$gap-sp: 10px;

.ho-top-event-list {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-pc;
  @include m.sp() {
    gap: $gap-sp;
  }

  > .item {
    width: calc((100% - $gap-pc * 2) / 3);

    &:nth-child(n + 4) {
      display: none;
    }
    @include m.sp() {
      width: 100%;

      &:nth-child(n + 2) {
        width: calc((100% - $gap-sp) / 2);
      }

      &:nth-child(n + 4) {
        display: none;
      }
    }
  }
}
</style>
