<i18n lang="yaml">
ja:
  error:
    fetchData: データの読み込みに失敗しました
    createRoom: トークルームの作成に失敗しました。
    not-profile: プロフィール情報の取得に失敗しました。
    empty-room: ルームがないため、トークルームを作成できませんでした。
    enter: 入室処理でエラーが発生しました。
en:
  error:
    fetchData: Failed to load data
    createRoom: Failed to create talk room.
    not-profile: Failed to retrieve profile information.
    empty-room: Could not create a talk room because there is no room.
    enter: An error occurred in the room entry process.
</i18n>

<template>
  <HtTop
    v-model:is-show-achievement-modal="isShowAchievementModal"
    :avatar="myAvatar"
    :room="myRoom"
    :talk-room="topTalkRoomList"
    @create-room="onCreateRoom"
    @enter-in-game="onEnterInGame"
  />
</template>
<script setup lang="ts">
// models
import { AvatarData } from '@/models/avatar'
import { RoomData } from '@/models/housing'
import { PositionChannel } from '@/models/channelSession'
import { isPwa } from '@/utils/user-agent'

// composables
import type { EnterInGameOption } from '@/composables/useInGame'

const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')
const config = useRuntimeConfig()
const ogImageUrl = computed(() => {
  const fileName = isJa.value ? 'ogp.png?20240605' : 'ogp_en.png?20240605'
  return `${config.public.NUXT_ENV_URL}/images/${fileName}`
})
const route = useRoute()

useHead({
  title: i18n.t('global-title'),
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: i18n.t('global-description'),
    },
    {
      name: 'keywords',
      content: i18n.t('global-keywords'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: i18n.t('global-title'),
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: i18n.t('global-description'),
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: ogImageUrl.value,
    },
  ],
})

definePageMeta({
  layout: 'default',
})

const { startLoading, endLoading } = inject(rootInjectionKey)!
const { addToast } = inject(toastInjectionKey)!

const avatar = useAvatar()
const worldPortal = useWorldPortal()
const news = useNews()
const myVketEvent = useMyVketEvent()
const housingWorlds = useHousingWorlds()
const cloudStore = useCloudStore()
const housing = useHousing()
const webPush = useWebPush()
const channelSession = useChannelSession()
const visitWorld = useVisitWorld()
const present = usePresent()
const inGame = useInGame()
const achievement = useAchievement()

provide(worldPortalComposablesInjectionKey, worldPortal)
provide(newsComposablesInjectionKey, news)
provide(myVketEventComposableInjectionKey, myVketEvent)
provide(housingWorldsInjectionKey, housingWorlds)
provide(cloudStoreInjectionKey, cloudStore)
provide(housingInjectionKey, housing)
provide(ChannelSessionInjectionKey, channelSession)
provide(visitWorldComposablesInjectionKey, visitWorld)
provide(presentComposablesInjectionKey, present)
provide(achievementInjectionKey, achievement)

const { isGottenMe } = inject(authInjectionKey)!
const { state } = inject(myProfileInjectionKey)!
const { getAvatar } = avatar
const { getWorldPortalList } = worldPortal
const { getNewsList } = news
const { getMyVketEvents, getParticipatingMyVketEvent } = myVketEvent
const { getHousingWorlds, getHousingWorldsList } = housingWorlds
const { getItemsPublished, getForcedPrivateItems } = cloudStore
const { getNotifications } = inject(notificationInjectionKey)!
const { getMyRoom } = housing
const { subscribe } = webPush
const { searchPositionChannelForTalkRoom } = channelSession
const { getPresents } = present

const { getFollowingsForTalkRoom } = useFollowingList()
const { enterInGame, createTalkroomAndEnterInGame } = inGame
const { postGrantAchievement } = achievement

const myAvatar = ref<AvatarData | null>(null)
const myRoom = ref<RoomData | null>(null)
const topTalkRoomList = ref<PositionChannel[]>([])
const isShowAchievementModal = ref<boolean>(false)

const vketId = computed(() => {
  return state.value.profile?.user.vketId
})

const myProfile = computed(() => state.value.profile)

/** アバターとルームを初期化 */
const init = () => {
  if (state.value.profile?.topPageAvatarId) {
    getAvatar(state.value.profile.topPageAvatarId)
      .then((_avatar) => {
        // NOTE: トップに設定したアバターが削除されている場合がある
        if (_avatar && _avatar.latestVersion?.downloadUrl) {
          myAvatar.value = _avatar
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  // NOTE: 背景画像取得するまでローディングを表示する
  startLoading()
  if (!state.value.profile?.vketId) {
    console.error('vketId is not found')
    endLoading()
    return
  }

  getMyRoom(`MyRoom-${state.value.profile.vketId}`)
    .then((_room) => {
      myRoom.value = _room
    })
    .catch((e) => console.error(e))
    .finally(() => endLoading())
}

/** トークルームの初期化 */
const initTalkRoom = async () => {
  const followerList = await (() => {
    if (!myProfile.value) return []
    return getFollowingsForTalkRoom(myProfile.value.vketId)
  })()

  const talkRoomList = await searchPositionChannelForTalkRoom(
    followerList.map((item) => item.vketId)
  )
  // TOPのトークルームを最大5件表示を行う
  topTalkRoomList.value = talkRoomList.slice(0, 5)
  const worldCodeList = topTalkRoomList.value.map((item) => item.worldCode)
  await getHousingWorldsList(worldCodeList)
}

/** メダルを付与する */
const grantAchievement = (label: string) => {
  return postGrantAchievement({ label })
    .then(() => {
      isShowAchievementModal.value = true
    })
    .catch(() => {
      /**
       * メダル付与の機能があることをユーザーが認識しにくいよう、通知しない
       * @see https://discord.com/channels/596169759045976094/1106113874366840972/1225399795418661014
       */
    })
}

/** お知らせなどの初期化 */
const initInformation = async () => {
  const { achievement, summerachievement } = { ...route.query } as Record<
    string,
    string
  >

  await Promise.all([
    getNewsList({
      limit: 8,
    }),
    getParticipatingMyVketEvent({
      limit: 6,
    }),
    getNotifications(10),
    getPresents({ isOpened: false }),
    myProfile.value?.isCompleteTutorial &&
      achievement &&
      grantAchievement(achievement),
    myProfile.value?.isCompleteTutorial &&
      summerachievement &&
      grantAchievement(summerachievement),
    getForcedPrivateItems(),
  ])
}

watch([vketId], async () => {
  try {
    await Promise.all([init(), initInformation(), initTalkRoom()])
    // NOTE: ダイアログが出るのでPromise.allからは外す
    // NOTE: iOSの場合、PWAインストールが必要なので、ここで確認する
    if (isIos() && !isPwa()) return
    await subscribe()
  } catch (e) {
    console.error(e)
    addToast(i18n.t('error.fetchData'), 'error')
  }
})

// 未ログイン表示の情報取得
if (!process.client || !isGottenMe.value) {
  try {
    await Promise.all([
      getNewsList({
        limit: 8,
      }),
      getWorldPortalList({
        limit: 7,
      }),
      getHousingWorlds(6, 0),
      getMyVketEvents({
        limit: 3,
      }),
      getItemsPublished({
        limit: 5,
      }),
    ])
  } catch (e) {
    console.error(e)
    addToast(i18n.t('error.fetchData'), 'error')
  }
}

// トークルームへの作成
const onCreateRoom = (roomName: string) => {
  startLoading()
  createTalkroomAndEnterInGame(roomName)
    .catch((error) => {
      console.error(error)
      addToast(i18n.t('error.createRoom'), 'error')
    })
    .finally(() => {
      endLoading()
    })
}

// インゲームに遷移
const onEnterInGame = (options: EnterInGameOption) => {
  try {
    enterInGame(options)
  } catch (error) {
    console.error(error)
    addToast(i18n.t('error.enter'), 'error')
  }
}

onMounted(async () => {
  if (vketId.value) {
    await Promise.all([init(), initInformation(), initTalkRoom()])
  }
})
</script>
