<i18n lang="yaml">
ja:
  visitor-count: '{ count }人参加中'
en:
  visitor-count: '{ count }people'
</i18n>

<template>
  <div class="ho-talkroom-card-for-top">
    <div class="image-wrapper">
      <HaBaseImage class="image" :src="thumbnailUrl" />
      <div class="user">
        <HmUserIcon class="icon" :src="hostUser?.icon.url || ''" />
        <p class="user-name">{{ hostUser?.name || '' }}</p>
      </div>
    </div>
    <div class="info-wrapper">
      <div class="name-wrapper">
        <p class="name">{{ positionChannel.name }}</p>
      </div>

      <div class="info">
        <div class="visitor">
          <ul class="icon-list">
            <ClientOnly>
              <li
                v-for="(icon, index) in participantIconList"
                :key="`visitor-${index}`"
                class="icon-area"
                :style="{ '--z-number': -index }"
              >
                <HmUserIcon :src="icon" class="icon" />
              </li>
            </ClientOnly>
          </ul>
          <div class="count-area">
            <IconUser class="icon" />
            <p>
              <span class="count-text -sp">{{
                i18n.t('visitor-count', { count: entryCount })
              }}</span>
              <span class="count-text -pc">{{ entryCount }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DeepReadonly } from 'vue'
import { PositionChannel } from '@/models/channelSession'
import IconUser from '@/assets/icons/icon_visitor.svg'
import { HousingWorld } from '@/models/housingWorld'

type Props = {
  talkroom: DeepReadonly<PositionChannel>
  housingWorld: DeepReadonly<HousingWorld> | null
}
const props = defineProps<Props>()

const i18n = useI18n()

const hostUser = computed(() => props.housingWorld?.user || null)
const positionChannel = computed(() => props.talkroom)
const userList = computed(() => props.talkroom.users)

const participantIconList = computed(() => {
  return userList.value.slice(0, 3).map((item) => item.icon)
})

const thumbnailUrl = computed(() => props.housingWorld?.thumbnail.url || '')

const entryCount = computed(() => props.talkroom.users.length)
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-talkroom-card-for-top {
  background: rgba(v.$text-body, 0.5);
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 105px;
  width: auto;

  @include m.tb {
    background: transparent;
    display: flex;
    height: auto;
  }

  &:hover,
  &:focus,
  &:active {
    .name {
      color: v.$yellow;
      transition: 0.3s;
    }

    .image {
      animation: 0.3s ease forwards;
      filter: saturate(1.5) brightness(1.1);
      transform: scale(1.1);
      transition: 0.3s;
    }
  }

  .image-wrapper {
    display: grid;
    overflow: hidden;
    position: relative;
    width: 187px;

    @include m.tb {
      display: none;
    }

    &::after {
      background: linear-gradient(
        180deg,
        rgba(17, 24, 39, 0) 80%,
        rgba(17, 24, 39, 0.2) 100%
      );
      content: '';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }

    > .image {
      aspect-ratio: 16/9;
      border-radius: 5px 0 0 5px;
      height: 100%;
      object-fit: cover;
    }

    > .user {
      bottom: v.space(1);
      display: flex;
      gap: v.space(0.5);
      margin: 0 v.space(2.5) 0 3px;
      position: absolute;

      > .icon {
        height: 15px;
        width: 15px;
      }

      > .user-name {
        -webkit-box-orient: vertical;
        color: v.$white;
        display: -webkit-box;
        font-size: 10px;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    padding: v.space(2);
    @include m.tb {
      padding: 0;
    }
  }

  .info {
    padding: 0;
  }

  .name-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin: 0;

    @include m.tb {
      display: none;
    }

    > .name {
      -webkit-box-orient: vertical;
      color: v.$white;
      display: -webkit-box;
      font-size: 14px;
      font-weight: 700;
      -webkit-line-clamp: 3;
      line-height: 16px;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .visitor {
    align-items: center;
    background: rgba(v.$text-body, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: v.space(2.5);
    margin-top: 0;
    padding: 2px 4px 2px 2px;

    @include m.tb {
      background: transparent;
      border-radius: 0;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .icon-list {
    // li要素のz-indexにマイナスをつける影響への対策
    position: relative;
    z-index: 1;

    @include m.tb {
      background-color: v.$yellow;
      border-radius: 50px;
      display: flex;
    }

    .icon-area {
      display: inline-flex;
      height: 30px;
      position: relative;
      width: 30px;
      z-index: var(--z-number);

      @include m.tb {
        height: 50px;
        width: 50px;
      }

      &:not(:last-child) {
        margin-right: v.space(-2);
      }

      .icon {
        border: solid 2px v.$yellow;
      }
    }
  }

  .count-area {
    align-items: center;
    color: v.$white;
    display: flex;
    gap: v.space(1);
    margin-right: v.space(1);

    @include m.tb {
      font-size: 10px;
      line-height: 14px;
      white-space: nowrap;
    }

    > .icon {
      fill: v.$white;
      height: 12px;
      margin-top: 1px;
      width: 12px;

      @include m.tb {
        height: 8px;
        width: 8px;
      }
    }
  }

  .count-text {
    &.-sp {
      display: none;

      @include m.tb {
        display: inline-block;
      }
    }

    &.-pc {
      display: inline-block;
      @include m.tb {
        display: none;
      }
    }
  }
}
</style>
