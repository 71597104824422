<i18n lang="yaml">
ja:
  text:
    title: 限定メダルを入手しました
    description: 「{ title }」を入手しました
  button: メダル一覧へ
  stamprally-button: VketRealスタンプラリーを確認する
en:
  text:
    title: Limited medals available.
    description: I got a "{ title }"
  button: To Medal List
  stamprally-button: View the VketReal Stamp Rally page
</i18n>
<template>
  <HaBaseDialog class="ho-top-achievement-modal" @close="close">
    <div ref="dialogInner" class="inner">
      <div class="header-container">
        <div class="title">
          {{ i18n.t('text.title') }}
        </div>
      </div>
      <div class="body-container">
        <div class="thumbnail-container">
          <div class="thumbnail">
            <HaBaseImage :src="imgSrc" class="image" />
            <img src="/images/achievement/glory.webp" class="background" />
          </div>
        </div>
        <p class="description">
          {{ i18n.t('text.description', { title }) }}
        </p>
        <div class="button-container">
          <template v-if="isSummerAchievement">
            <HaLink :to="LINK.STAMPRALLY2024" blank class="link">
              <HoMyVketButton class="button">
                <span>{{ i18n.t('stamprally-button') }}</span>
              </HoMyVketButton>
            </HaLink>
          </template>
          <template v-else>
            <HaLink to="/mypage/achievement" class="link">
              <HoMyVketButton class="button">
                <span>{{ i18n.t('button') }}</span>
              </HoMyVketButton>
            </HaLink>
          </template>
        </div>
      </div>
    </div>
    <!-- 紙吹雪エフェクト用canvas -->
    <canvas ref="confettiCanvas" class="confetti-canvas" />
  </HaBaseDialog>
</template>

<script lang="ts" setup>
import confetti, { create } from 'canvas-confetti'

type ConfettiCanvas = HTMLCanvasElement & { confetti: confetti.CreateTypes }
type Props = {
  title: string
  imgSrc: string
}

defineProps<Props>()

const i18n = useI18n()
const route = useRoute()

type Emits = {
  (emit: 'close'): void
}

const emit = defineEmits<Emits>()

const close = () => {
  emit('close')
}

const dialogInner = ref<HTMLDivElement | null>(null)
const confettiCanvas = ref<ConfettiCanvas | null>(null)
const isSummerAchievement = ref<boolean>(false)

const { summerachievement } = { ...route.query } as Record<string, string>

if (summerachievement) {
  isSummerAchievement.value = true
}

onMounted(async () => {
  const canvas = confettiCanvas.value
  if (!canvas) return

  canvas.confetti = canvas.confetti || create(canvas, { resize: true })

  const dialogWidth = dialogInner.value ? dialogInner.value.clientWidth : 0
  const windowWidth = window.innerWidth

  // モーダルの左端(割合)
  const leftX = (windowWidth - dialogWidth) / (2 * windowWidth)

  await new Promise((resolve) => setTimeout(resolve, 1000))
  canvas.confetti({
    angle: 60,
    particleCount: 100,
    spread: 50,
    origin: { x: leftX, y: 0.6 },
    scalar: 0.8,
  })

  await new Promise((resolve) => setTimeout(resolve, 1000))
  canvas.confetti({
    angle: 120,
    particleCount: 100,
    spread: 50,
    origin: { x: 1 - leftX, y: 0.6 },
    scalar: 0.8,
  })
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-top-achievement-modal {
  // 詳細度を上げるためにclass属性を指定
  &:deep(> .dialog-window[class]) {
    background-color: v.$white;
    border-radius: 10px;
    max-height: 100%;

    > .content {
      padding: 0;
    }
  }

  .inner {
    color: v.$white;
    width: 100%;
  }

  @include m.tb() {
    padding: v.space(4);
  }
}

.header-container {
  > .title {
    background-color: v.$gray;
    border-top-left-radius: 10px;
    color: v.$text-body;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    padding: v.space(8) v.space(8) v.space(4) v.space(8);
    text-align: center;

    @include m.tb() {
      font-size: 16px;
      padding: v.space(4);
    }
  }
}

.body-container {
  display: grid;
  gap: v.space(4);
  height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: v.space(4) v.space(8) v.space(8) v.space(8);
  width: 640px;
  @extend %scroll-bar;

  @include m.tb() {
    height: 60svh;
    padding: v.space(4) v.space(4) v.space(6) v.space(4);
    width: 100%;
  }

  > .description {
    color: v.$text-body;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.thumbnail-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  > .thumbnail {
    align-items: center;
    display: flex;
    height: 300px;
    justify-content: center;
    position: relative;
    width: 300px;

    > .image {
      height: 100%;
      max-height: 200px;
      max-width: 200px;
      object-fit: contain;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    > .background {
      animation: bgRotation 8s infinite linear;
      height: 100%;
      max-height: 350px;
      max-width: 350px;
      object-fit: contain;
      position: absolute;
      width: 100%;
    }

    @include m.tb() {
      padding: v.space(4);
    }
  }
}

.button-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  .link > .button {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: 56px;
    justify-content: center;
    padding: v.space(1);
    position: relative;
    width: 480px;

    @include m.tb() {
      font-size: 14px;
      height: 36px;
      width: 311px;
    }
  }
}

@keyframes bgRotation {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.confetti-canvas {
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
</style>
