<i18n lang="yaml">
ja:
  count:
    follow: フォロー
    follower: フォロワー
  myRoom: マイルームへ
  gift: プレゼント
  messagePage: メッセージページ
  share: シェア
  dialog:
    shareLink:
      message: 友達にシェアしよう
en:
  count:
    follow: follow
    follower: follower
  myRoom: To Myroom
  gift: Gifts
  messagePage: Message Page
  share: Share
  dialog:
    shareLink:
      message: Share with your friends
</i18n>

<template>
  <div class="ht-top">
    <ClientOnly>
      <template v-if="isGottenMe">
        <!-- ▼▼▼ ログイン時に表示するコンテンツ ▼▼▼ -->
        <div class="ht-top-login">
          <div class="vrmviewer-container" :class="{ '-safari': styleSafari }">
            <!-- ▼▼▼ VrmViewer ▼▼▼ -->
            <HoVrmViewer
              class="vrmviewer"
              :vrm-file="
                avatar && avatar.latestVersion.downloadUrl
                  ? avatar.latestVersion.downloadUrl.url
                  : undefined
              "
              :settings="vrmViewerSettings"
            />
            <!-- ▲▲▲ VrmViewer ▲▲▲ -->

            <!-- ▼▼▼ イベント ▼▼▼ -->
            <div class="event-box-container">
              <!-- ▼▼▼ ニュース一覧 ▼▼▼ -->
              <HoTopEventBoxNewsList />
              <!-- ▲▲▲ ニュース一覧 ▲▲▲ -->
            </div>
            <!-- ▲▲▲ イベント ▲▲▲ -->

            <!-- ▼▼▼ 現在のトークルーム ▼▼▼ -->
            <div class="talkroom-container">
              <HoTopTalkroomList
                :channel-session-list="talkRoom"
                @create-room="onCreateRoom"
                @enter-housing-world="onEnterInGame"
              />
            </div>
            <!-- ▲▲▲ 現在のトークルーム ▲▲▲ -->

            <!-- ▼▼▼ ボタン ▼▼▼ -->
            <div class="buttons-container">
              <div class="buttons">
                <HaLink
                  to="/mypage/notification/gift"
                  class="button -present"
                  :class="{ '-en': !isJa }"
                >
                  <span class="screen-reader">{{ i18n.t('gift') }}</span>
                  <template v-if="presentCount">
                    <span class="badge">{{ presentCount }}</span>
                  </template>
                </HaLink>
                <HaLink
                  to="/mypage/message"
                  class="button -message"
                  :class="{ '-en': !isJa }"
                >
                  <span class="screen-reader">{{ i18n.t('messagePage') }}</span>
                  <template v-if="messageCount">
                    <span class="badge">{{ messageCount }}</span>
                  </template>
                </HaLink>
                <HaBaseButton
                  class="button -share"
                  :class="{ '-en': !isJa }"
                  @click="openShareLinkDialog()"
                  ><span class="screen-reader">{{
                    i18n.t('share')
                  }}</span></HaBaseButton
                >
              </div>
            </div>
            <!-- ▲▲▲ ボタン ▲▲▲ -->
            <!-- ▼▼▼ 情報 ▼▼▼ -->
            <div class="information-box-container">
              <HoTopInformationBox
                @click-share="openShareLinkDialog"
                @enter-housing-world="onEnterInGame"
              />
            </div>
            <!-- ▲▲▲ 情報 ▲▲▲ -->

            <!-- ▼▼▼ 近日参加予定 ▼▼▼ -->
            <div class="event-Participate-container">
              <HoTopEventBoxParticipate />
            </div>
            <!-- ▲▲▲ 近日参加予定 ▲▲▲ -->

            <!-- ▼▼▼ マイルームリンク ▼▼▼ -->
            <div class="myroom-button-container">
              <HaLink
                class="myroom-button"
                :class="{ '-en': !isJa }"
                @click="
                  onEnterInGame({
                    worldId: room?.worldId || '',
                  })
                "
              >
                <span class="screen-reader">{{ i18n.t('myRoom') }}</span>
              </HaLink>
            </div>
            <!-- ▲▲▲ マイルームリンク ▲▲▲ -->
          </div>
        </div>
        <!-- ▲▲▲ ログイン時に表示するコンテンツ ▲▲▲ -->

        <!-- ▼▼▼ メダル獲得モーダル ▼▼▼ -->
        <template
          v-if="isShowAchievementModal && achievementState.grantAchievement"
        >
          <HoAchievementModal
            :title="
              isJa
                ? achievementState.grantAchievement.title
                : achievementState.grantAchievement.titleEn
            "
            :img-src="achievementState.grantAchievement.thumbnail.url"
            @close="closeAchievementModal"
          />
        </template>
        <!-- ▲▲▲ メダル獲得モーダル ▲▲▲ -->
      </template>

      <template v-if="!isGottenMe">
        <!-- ▼▼▼ 未ログイン時に表示するコンテンツ ▼▼▼ -->
        <HoTopNotLogin @enter-in-game="onEnterInGame" />
        <!-- ▲▲▲ 未ログイン時に表示するコンテンツ ▲▲▲ -->
      </template>
    </ClientOnly>

    <!-- ▼▼▼ 友達に教えるダイアログ ▼▼▼ -->
    <template v-if="isOpenShareLinkDialog">
      <HoShareLinkDialog
        :is-open="isOpenShareLinkDialog"
        :message="i18n.t('dialog.shareLink.message')"
        :custom-share-url="`${config.public.NUXT_ENV_URL}${roomPagePath}`"
        @confirm="login"
        @cancel="closeShareLinkDialog"
        @close="closeShareLinkDialog"
      />
    </template>
    <!-- ▲▲▲ 友達に教えるダイアログ ▲▲▲ -->

    <!-- ▼▼▼ お知らせダイアログ ▼▼▼ -->
    <!-- 出し分けはコンポーネント内で行う -->
    <HoTopNotificationDialog
      @confirm="
        onEnterInGame({
          worldId: props.room?.worldId || '',
        })
      "
    />
    <!-- ▲▲▲ お知らせダイアログ ▲▲▲ -->

    <!-- ▼▼▼ アイテムBANダイアログ ▼▼▼ -->
    <HoCloudStoreBanDialog />
    <!-- ▲▲▲ アイテムBANダイアログ ▲▲▲ -->

    <!--  詳細モーダル  -->
    <HoTutorialStartModal
      v-show="isShowTutorial"
      @start-tutorial="onClickStartTutorial"
      @close="closeTutorialModal"
    />
  </div>
</template>

<script lang="ts" setup>
// models
import { AvatarData } from '@/models/avatar'
import { RoomData } from '@/models/housing'
import { PositionChannel } from '@/models/channelSession'

// composables
import { breakpoints } from '@/composables/useBreakpoints'
import { authInjectionKey } from '@/composables/useAuth'
import { ViewerSettings } from '@/composables/useVrmViewer'
import type { EnterInGameOption } from '@/composables/useInGame'

import { convertNumberToPlus } from '@/utils/math'

// content
import { PRESET_HOUSING_NAME } from '@/utils/constants'

type Props = {
  avatar?: AvatarData | null
  room?: RoomData | null
  talkRoom: PositionChannel[]
  isShowAchievementModal: boolean
}
const props = defineProps<Props>()
type Emits = {
  (e: 'createRoom', roomName: string): void
  (e: 'enterInGame', options: EnterInGameOption): void
  (e: 'update:isShowAchievementModal', value: boolean): void
}
const emit = defineEmits<Emits>()

const i18n = useI18n()
const toast = useToast()

const { addToast } = toast
const isJa = computed(() => i18n.locale.value === 'ja')

const { state } = inject(rootInjectionKey)!

const {
  playAnimation,
  changeCameraHeight,
  changeCameraRotate,
  changeCameraDistance,
  isLoading,
  state: vrmState,
} = useVrmViewer()

const { isGottenMe, login, me } = inject(authInjectionKey)!
const { total } = inject(presentComposablesInjectionKey)!
const { state: notificationState } = inject(notificationInjectionKey)!
const { state: achievementState } =
  inject(achievementInjectionKey) ??
  raiseError('achievementInjectionKey is not provided')

const config = useRuntimeConfig()

const roomPagePath = computed(() => {
  // NOTE:非公開の条件分岐は万が一のため対応（ルームはすべて公開になる想定）
  if (me.value && props?.room?.published) {
    return `/room/${props.room.worldId}`
  } else {
    return '/myroom/list?sort=newer'
  }
})

/** VRM Viewerが初回描画したか */
const isLoadedVrm = computed(() => vrmState.isLoaded)
const presentCount = computed(() =>
  total.value ? convertNumberToPlus(total.value, 99) : 0
)
const messageCount = computed(
  () => notificationState.summary?.messageUnreadCount
)

// iosの時に自動再生するためのフラグ（iPad Safari用にmac判定もする）
// const isMuteAutoplay = isIos() || isMac()
const isPlay = ref(false)
const styleSafari = ref(false)

watch(
  () => state.isLoading,
  (val) => {
    if (val) {
      isPlay.value = false
      return
    }
    isPlay.value = true
  }
)

const isPc = breakpoints.greater('pc')
const isTb = breakpoints.smaller('pc')
const isSp = breakpoints.smaller('sp')

const presetBackgroundImage = computed(() => {
  switch (props.room?.presetHousingHome.name) {
    case PRESET_HOUSING_NAME.PINK: // ピンク
      return '/images/vrm-viewer-bg-sweet.webp'
    case PRESET_HOUSING_NAME.NATURAL: // ナチュラル
      return '/images/vrm-viewer-bg-natural.webp'
    case PRESET_HOUSING_NAME.CHIC: // シック
      return '/images/vrm-viewer-bg-modern.webp'
    case PRESET_HOUSING_NAME.GREEN: // グリーン
      return '/images/vrm-viewer-bg-green.webp'
    case PRESET_HOUSING_NAME.PURPLE: // パープル
      return '/images/vrm-viewer-bg-purple.webp'
    case PRESET_HOUSING_NAME.MUSV: // Musv
      return '/images/vrm-viewer-bg-musv.webp'
    case PRESET_HOUSING_NAME.KANAHEI: // カナヘイ
      return '/images/vrm-viewer-bg-yurutto.webp'
    case PRESET_HOUSING_NAME.SORGE: // ソルゲ
      return '/images/vrm-viewer-bg-sorge.webp'
    case PRESET_HOUSING_NAME.SIMPLE: // シンプル
      return '/images/vrm-viewer-bg-simple.webp'
    case PRESET_HOUSING_NAME.MIYAVI: // MIYAVI
      return '/images/vrm-viewer-bg-miyavi.webp'
    case PRESET_HOUSING_NAME.HALLOWEEN: // ハロウィン
      return '/images/vrm-viewer-bg-halloween.webp'
    case PRESET_HOUSING_NAME.GAJOEN: // 牙城園
      return '/images/vrm-viewer-bg-gajoen.webp'
    case PRESET_HOUSING_NAME.WAFU: // 五色屋＜払暁＞
      return '/images/vrm-viewer-bg-wafu.webp'
    case PRESET_HOUSING_NAME.CLASSROOM: // 教室
      return '/images/vrm-viewer-bg-classroom.webp'
    default:
      return '/images/vrm-viewer-bg-natural.webp'
  }
})

const vrmViewerSettings = computed<ViewerSettings>(() => ({
  cameraHeightPc: 1.0,
  backgroundImagePc: presetBackgroundImage.value || '',
  backgroundImageSp: presetBackgroundImage.value || '',
}))

const adjustVrm = () => {
  adjustVrmHeight()
  adjustVrmCamera()
  adjustVrmDistance()
}

const adjustVrmDistance = () => {
  if (!vrmState.avatarHeight) {
    return
  }

  if (isPc.value) {
    changeCameraDistance(vrmState.avatarHeight * 2)
  } else {
    changeCameraDistance(vrmState.avatarHeight * 1.7)
  }
}

const adjustVrmCamera = () => {
  if (isPc.value) {
    changeCameraRotate(12, 0, 0)
  } else {
    changeCameraRotate(10, 0, 0)
  }
}

const adjustVrmHeight = () => {
  if (!vrmState.avatarHeight) {
    return
  }

  if (isPc.value) {
    changeCameraHeight(`${vrmState.avatarHeight * 0.8}`)
  } else {
    changeCameraHeight(`${vrmState.avatarHeight * 0.8}`)
  }
}

watch(isSp, () => {
  isPlay.value = true
})

watch([isPc, isTb, isSp], () => {
  adjustVrm()
})

// note: isLoadingの後にisLoadedVrmをチェックする
watch([isLoading], () => {
  // note: デフォルトpcサイズ以下の時に高さ調節する
  if (isLoadedVrm.value) {
    adjustVrm()
  }
})

const onClickVRMView = () => {
  // 登録されているアニメーションからランダムなものを再生する
  const animationLength = 3
  playAnimation(Math.floor(Math.random() * animationLength))
}

const isShowTutorial = ref(false)

const closeTutorialModal = () => {
  isShowTutorial.value = false
}

const closeAchievementModal = () => {
  emit('update:isShowAchievementModal', false)
}

/**
 * チュートリアルの開始
 */
const onClickStartTutorial = async () => {
  if (isGottenMe.value) {
    // NOTE: Heliodorを使っているのでrouter.pushは使えない
    location.href = '/tutorial'
  } else {
    const result = await login()
    if (result) {
      addToast(i18n.t(result), 'error')
    }
  }
}

const onCreateRoom = (roomName: string) => {
  emit('createRoom', roomName)
}

// インゲームに遷移
const onEnterInGame = (options: EnterInGameOption) => {
  emit('enterInGame', options)
}

// 友達に教えるダイアログ
const isOpenShareLinkDialog = ref(false)

const openShareLinkDialog = () => {
  isOpenShareLinkDialog.value = true
}

const closeShareLinkDialog = () => {
  isOpenShareLinkDialog.value = false
}

onMounted(() => {
  styleSafari.value = isSafari() || false
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/breakpoints.module.scss' as b;

// note: 内部でmixin使っているのでextendにできない
@mixin height-control {
  height: v.$content-height-pc;
  max-height: v.$content-height-pc;
  min-height: v.$content-height-pc;

  @include m.tb {
    height: v.$content-height-tb;
    max-height: v.$content-height-tb;
    min-height: v.$content-height-tb;
  }

  @include m.sp {
    height: v.$content-height-sp;
    max-height: v.$content-height-sp;
    min-height: v.$content-height-sp;

    &.-safari {
      @media (display-mode: standalone) {
        height: v.$content-height-sp-pwa-safari;
        max-height: v.$content-height-sp-pwa-safari;
        min-height: v.$content-height-sp-pwa-safari;
      }
    }
  }

  @include m.landscape {
    min-height: v.$content-height-landscape;
  }
}

.ht-top-login {
  > .vrmviewer-container {
    @include height-control();
    position: relative;

    > .vrmviewer {
      height: 100%;
    }

    > .talkroom-container {
      bottom: v.space(8);
      left: v.space(8);
      position: absolute;

      @include m.tb {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    > .event-box-container {
      left: v.space(8);
      position: absolute;
      top: v.space(8);
      @include m.tb {
        bottom: v.space(26);
        left: initial;
        right: v.space(2.5);
        top: initial;
      }
    }

    > .event-Participate-container {
      bottom: v.space(8);
      position: absolute;
      right: v.space(8);
      @include m.tb {
        bottom: v.space(26);
        left: v.space(2.5);
        right: initial;
        top: initial;
      }
    }

    > .information-box-container {
      bottom: v.space(8);
      position: absolute;
      right: v.space(8);
      top: v.space(8);
      width: fit-content;
      @include m.tb {
        bottom: v.space(10);
        left: v.space(2);
        right: 0;
        top: initial;
      }
    }
  }
}

.buttons-container {
  position: absolute;
  right: v.space(102);
  top: v.space(8);

  @include m.tb {
    bottom: v.space(8);
    right: v.space(2.5);
    top: initial;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: v.space(6);

    > .button {
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 80px;
      position: relative;
      width: 80px;
      @include m.tb {
        height: 60px;
        width: 60px;
      }

      &.-present {
        background-image: url('/images/top/new/button_gift.webp');

        &:hover {
          background-image: url('/images/top/new/button_gift_on.webp');
        }

        &.-en {
          background-image: url('/images/top/new/button_gift_en.webp');

          &:hover {
            background-image: url('/images/top/new/button_gift_en_on.webp');
          }
        }
      }

      &.-message {
        background-image: url('/images/top/new/button_message.webp');

        &:hover {
          background-image: url('/images/top/new/button_message_on.webp');
        }

        &.-en {
          background-image: url('/images/top/new/button_message_en.webp');

          &:hover {
            background-image: url('/images/top/new/button_message_en_on.webp');
          }
        }

        @include m.tb {
          display: none;
        }
      }

      &.-share {
        background-image: url('/images/top/new/button_share.webp');

        &:hover {
          background-image: url('/images/top/new/button_share_on.webp');
        }

        &.-en {
          background-image: url('/images/top/new/button_share_en.webp');

          &:hover {
            background-image: url('/images/top/new/button_share_en_on.webp');
          }
        }

        @include m.tb {
          display: none;
        }
      }

      > .badge {
        align-items: center;
        background: v.$pink-1;
        border-radius: 50%;
        color: v.$white;
        display: flex;
        height: 30px;
        justify-content: center;
        line-height: 1;
        position: absolute;
        right: -8px;
        text-align: center;
        top: -8px;
        width: 30px;
        z-index: 1;
        @include m.tb {
          font-size: 12px;
          height: 20px;
          right: -2px;
          top: -2px;
          width: 20px;
        }
      }
    }
  }
}

.icon {
  &.-myvket {
    margin-right: v.space(1);
    width: 20px;

    > :deep(path) {
      fill: v.$white;
    }
  }
}

.myroom-button-container {
  bottom: v.space(8);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 320px;

  @include m.tb {
    display: none;
  }

  > .myroom-button {
    aspect-ratio: 320 / 72;
    background-image: url('/images/top/new/button_myroom.webp');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: relative;

    &:hover {
      background-image: url('/images/top/new/button_myroom_on.webp');
    }

    &.-en {
      background-image: url('/images/top/new/button_myroom_en.webp');

      &:hover {
        background-image: url('/images/top/new/button_myroom_en_on.webp');
      }
    }
  }
}

.screen-reader {
  @include m.sr-only();
}
</style>
