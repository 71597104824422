<i18n lang="yaml">
ja:
  head: お知らせ
en:
  head: Notification
</i18n>

<template>
  <div class="ho-top-event-box-news-list">
    <div class="head">
      <div class="text">{{ i18n.t('head') }}</div>
    </div>
    <div class="content">
      <div class="list-container">
        <ClientOnly>
          <HmSwiper :slides-per-view="1" loop autoplay :pagination="true">
            <SwiperSlide v-for="news in newsList" :key="news.id">
              <div class="item">
                <HaLink
                  :to="isJa ? news.detailUrl : news.detailUrlEn"
                  blank
                  class="link"
                >
                  <HaBaseImage
                    :src="isJa ? news.banner.url : news.bannerEn.url"
                    no-image="/images/no-image-1024x512.webp"
                    class="image"
                    :alt="`サムネイル画像：${isJa ? news.title : news.titleEn}`"
                  />
                </HaLink>
              </div>
            </SwiperSlide>
          </HmSwiper>
        </ClientOnly>
      </div>
      <div class="swiper-pagination" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SwiperSlide } from 'swiper/vue'

const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')

// ニュース
const { state: newsState } = inject(newsComposablesInjectionKey)!
const newsList = computed(() => newsState.value.newsList)
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/breakpoints.module.scss' as b;

.ho-top-event-box-news-list {
  background: rgba(v.$text-body, 0.5);
  border-radius: 10px;
  padding: v.space(8) 0 v.space(4);
  position: relative;
  width: 424px;

  @media screen and (max-width: b.$pc-content-medium-width) {
    width: 320px;
  }
  @include m.tb {
    background: none;
    border-radius: 0;
    padding: 0;
    width: 156px;
  }

  > .head {
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: v.space(-4);
    @include m.tb {
      display: none;
    }

    > .text {
      align-items: center;
      background: v.$primary-color;
      border-radius: 5px;
      color: v.$white;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      height: 34px;
      justify-content: center;
      line-height: 1;
      min-width: 180px;
      padding: v.space(2);
    }
  }

  > .content {
    @extend %scroll-bar;
    display: grid;
    gap: v.space(4);
    grid-template-columns: repeat(1, 100%);
    max-height: calc(100vh - v.$header-height-pc - v.space(28));
    overflow: auto;
    padding: 0 v.space(4);
    @include m.tb {
      overflow: visible;
      padding: 0;
    }

    &.-has-participate {
      height: 400px;
      @include m.tb {
        height: auto;
      }
    }

    .participate {
      @include m.tb {
        display: none;
      }
    }
  }

  .list-container {
    border: 2px solid v.$white;
    border-radius: 3px;
    overflow: hidden;
  }

  .item {
    overflow: hidden;

    > .link {
      display: block;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(1.1);
      }

      > .image {
        aspect-ratio: 16 / 9;
      }
    }
  }

  // swiperのスタイル上書き
  --swiper-theme-color: #{v.$primary-color};
  --swiper-pagination-bullet-inactive-color: #{v.$text-body};
  --swiper-pagination-bullet-inactive-opacity: 0.375;

  .swiper-pagination {
    bottom: auto;
    left: auto;
    position: relative;
  }
}
</style>
