<template>
  <div class="ho-top-news-list">
    <Swiper
      ref="swiperRef"
      :slides-per-view="swiperConfig.slidesPerView"
      :space-between="swiperConfig.spaceBetween"
      :centered-slides="swiperConfig.centeredSlides"
      :loop="true"
      :autoplay="true"
      :navigation="swiperConfig.navigation"
      :modules="[Autoplay, Navigation]"
    >
      <SwiperSlide v-for="news in newsList" :key="news.id">
        <div class="item">
          <HaLink
            :to="isJa ? news.detailUrl : news.detailUrlEn"
            blank
            class="link"
          >
            <HaBaseImage
              :src="isJa ? news.banner.url : news.bannerEn.url"
              no-image="/images/no-image-1024x512.webp"
              class="image"
              :alt="`サムネイル画像：${isJa ? news.title : news.titleEn}`"
            />
          </HaLink>
        </div>
      </SwiperSlide>
    </Swiper>

    <HaBaseButton class="navigation --prev">
      <IconArrowNext class="icon" />
    </HaBaseButton>
    <HaBaseButton class="navigation --next">
      <IconArrowNext class="icon" />
    </HaBaseButton>
  </div>
</template>

<script lang="ts" setup>
import type { DeepReadonly } from 'vue'
// @see https://swiperjs.com/vue
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

// assets
import IconArrowNext from '@/assets/icons/icon_arrow_next.svg'

// composables
import { breakpoints } from '@/composables/useBreakpoints'

// models
import { NewsItem } from '@/models/news'

type Props = {
  newsList: DeepReadonly<NewsItem[]>
}
const props = defineProps<Props>()

const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')

// ナビゲーションで使うエレメントのクラスを指定
const swiperNavigation = {
  prevEl: '.navigation.--prev',
  nextEl: '.navigation.--next',
}

// カルーセル設定
const swiperConfig = computed(() => {
  if (process.client && breakpoints.smaller('sp').value) {
    // SP時の表示設定
    return {
      // 左右を少し見せるために1.5を設定
      slidesPerView: 1.5,
      spaceBetween: 10,
      navigation: false,
      centeredSlides: true,
    }
  }
  // PC時の表示設定
  return {
    slidesPerView: 3,
    spaceBetween: 20,
    navigation: swiperNavigation,
    centeredSlides: false,
  }
})
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-top-news-list {
  padding: 0 v.space(6);
  position: relative;
  @include m.sp() {
    margin: 0 v.space(-4);
    padding: 0;
  }

  .item {
    border-radius: 10px;
    overflow: hidden;

    > .link {
      display: block;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }

  > .navigation {
    align-items: center;
    background: v.$primary-color;
    border: 2px solid v.$white;
    border-radius: 50%;
    bottom: 0;
    display: flex;
    height: 52px;
    justify-content: center;
    margin: auto;
    position: absolute;
    top: 0;
    transition: background-color 0.2s;
    width: 52px;
    z-index: 1;

    > .icon {
      fill: v.$white;
      height: 20px;
      width: 20px;
    }

    &.--prev {
      left: 0;

      > .icon {
        transform: rotate(180deg);
      }
    }

    &.--next {
      right: 0;
    }

    &:hover {
      background: v.$primary-hover-color;
    }
    @include m.sp() {
      display: none;
    }
  }
}
</style>
